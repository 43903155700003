import { Components } from "@mui/material";

export const selectTheme: Components = {
  MuiSelect: {
    styleOverrides: {
      select: {
        outline: "none",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        outline: "none",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {},
  },
};
