import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

const apiStatusMiddleware: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
  if (action.payload && action.payload.result !== undefined) {
    return next({
      ...action,
      payload: action.payload.result,
    });
  } else {
    return next(action);
  }
};

export { apiStatusMiddleware };
