import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ESignMethod, EStepErrorType } from "ts/enums/enums";

import { IAsanCertificates, ICreateContractParams, IFileInfo, ILoginData } from "./joinFree/types";

export interface ContextState {
  phone: string;
  userId?: string;
  email?: string;
  transactionIdStr: string;
  transactionId2?: string;
  pin?: string;
  taxNoList?: string;
  certCode?: string;
  selectedCertificate?: IAsanCertificates;
  asanCertificates?: IAsanCertificates[];
  asanAdvanceUsers?: ILoginData[];
  jwtoken?: string;
  verificationCode?: string;
  fileInfo?: IFileInfo;
  pin1?: string;
  resultState?: boolean;
  stepErrorType?: EStepErrorType;
  isChatOpened?: boolean;
  signType?: ESignMethod;
  createContractData?: ICreateContractParams;
  isPinPending?: boolean;
}

const initialState: ContextState = {
  phone: "",
  userId: "",
  email: "",
  transactionIdStr: "",
  transactionId2: "",
  pin: "",
  taxNoList: "",
  certCode: "",
  jwtoken: "",
  verificationCode: "",
  pin1: "",
  resultState: false,
  isChatOpened: false,
  isPinPending: false,
};

const contextSlice = createSlice({
  name: "context",
  initialState: initialState,
  reducers: {
    setPinModal: (state, action: PayloadAction<boolean>) => {
      state.isPinPending = action.payload;
    },
    setChatSupport: (state, action: PayloadAction<boolean>) => {
      state.isChatOpened = action.payload;
    },
    setCheckWithAsanData: (
      state,
      action: PayloadAction<Pick<ContextState, "phone" | "transactionIdStr" | "verificationCode">>
    ) => {
      state.phone = action.payload.phone;
      state.transactionIdStr = action.payload.transactionIdStr;
      state.verificationCode = action.payload.verificationCode;
    },
    setAsanCertificates: (state, action: PayloadAction<Pick<ContextState, "asanCertificates">>) => {
      state.asanCertificates = action.payload.asanCertificates;
    },
    setStepError: (state, action: PayloadAction<Pick<ContextState, "stepErrorType">>) => {
      state.resultState = false;
      state.stepErrorType = action.payload.stepErrorType;
    },
    setToken: (state, action: PayloadAction<Pick<ContextState, "jwtoken">>) => {
      state.jwtoken = action.payload.jwtoken;
    },
    setSelectedCertificate: (state, action: PayloadAction<Pick<ContextState, "selectedCertificate">>) => {
      state.selectedCertificate = action.payload.selectedCertificate;
    },
    setFileInfo: (state, action: PayloadAction<Pick<ContextState, "fileInfo">>) => {
      state.fileInfo = action.payload.fileInfo;
    },
    setSignType: (state, action: PayloadAction<Pick<ContextState, "signType">>) => {
      state.signType = action.payload.signType;
    },
    setResultState: (state, action: PayloadAction<boolean>) => {
      state.resultState = action.payload;
    },
  },
});

export const {
  setPinModal,
  setChatSupport,
  setCheckWithAsanData,
  setAsanCertificates,
  setStepError,
  setToken,
  setSelectedCertificate,
  setFileInfo,
  setSignType,
  setResultState,
} = contextSlice.actions;
export default contextSlice;
