import { Components } from "@mui/material";

export const tooltipTheme: Components = {
  MuiTooltip: {
    styleOverrides: {
      arrow: {
        "&:before": {
          border: "1px solid #E6E8ED",
        },
        color: "#fff",
      },
      tooltip: {
        fontSize: "16px",
        backgroundColor: "#fff",
        borderRadius: "4px",
        boxShadow: "0 5px 15px 0 rgb(0 0 0 / 20%)",
        color: "#a0a4a8",
        padding: "15px",
      },
      popper: {},
    },
  },
};
