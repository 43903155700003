import { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import { theme } from "theme/index";

import i18n from "services/i18n";
import { setupStore } from "store";

import Loading from "components/Loading";
import ScrollToTop from "components/ScrollToTop";

import reportWebVitals from "./reportWebVitals";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

toast.configure();

const GlobalApp = lazy(() => import("./App"));

const store = setupStore();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <Route exact>
            <I18nextProvider i18n={i18n}>
              <ThemeProvider theme={theme}>
                <GlobalApp />
              </ThemeProvider>
            </I18nextProvider>
          </Route>
        </Suspense>
      </Router>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
