/** Icon align for Input with icon component */
export enum EIconAlign {
  LEFT = "left",
  RIGHT = "right",
}

/** Steps navigation item state */
export enum EStepNavItem {
  disabled,
  active,
  done,
}

/** Response status */
export enum EResponseStatus {
  OK = "0",
  OK123 = "110",
  REJECTED = "20",
  OUTSTANDING_TRANSACTION = "26",
  NO_VERSION_THREE = "404",
  // if user entered wrong data
  WRONG_INFO = "2004",
}

/** API request response modoel publish status */
export enum EAPIPublishStatus {
  ACTIVE = "ACTIVE",
  SOON = "SOON",
}

export enum ESignMethod {
  MB_SIGN = "CB_SIGN",
  SELF_SIGN = "SELF_SIGN",
}

export enum EStepErrorType {
  NO_CB_USER = 0,
  NO_ACCESS = 1,
  NO_ACCOUNT = 2,
  NO_VERSION_THREE = 3,
}

export enum ESteps {
  INFO = 1,
  CHOOSE_CERTIFICATE = 2,
  SIGN_METHOD = 3,
  SIGN_FILE = 4,
  RESULT = 5,
}

export enum ESupportSteps {
  CHOOSE_SUBJECT = 0,
  APPLY = 1,
  RESULT = 2,
  ECOM_INFO = 3,
}

export enum ETestApplySteps {
  APPLY = 0,
  RESULT = 1,
}

export enum ESupportSubjects {
  NON_STANDARD = "PARTNER_API",
  TEST_API = "TEST_ENVIRONMENT_API",
  REAL_API = "REAL_ENVIRONMENT_API",
  PARTNER_BE = "PARTNER_BE",
  ECOM = "E_COMM",
  OTHER = "OTHER",
}

export enum ELanguage {
  AZ = "AZ",
  EN = "EN",
  RU = "RU",
}

export enum EJoinFreeLoggerState {
  NO_COMPLETE = "NO_COMPLETE",
  REDIRECT_ONBOARDING = "REDIRECT_ONBOARDING",
  NO_OWNER = "NO_OWNER",
  NO_USER_CUSTOMER = "NO_USER_CUSTOMER",
  SUCCESS_COMPLETE = "SUCCESS_COMPLETE",
}

export enum EProductQuality {
  NONE = "NONE",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
}

export enum EApiFixedCacheKey {
  CHECK_WITH_ASAN = "CHECK_WITH_ASAN",
  LOGIN_WITH_ASAN = "LOGIN_WITH_ASAN",
  CREATE_CONTRACT = "CREATE_CONTRACT",
  SIGN_FILE = "SIGN_FILE",
  ACCESS_CREATE = "ACCESS_CREATE",
}

export enum EProducts {
  AUTH = "13",
  ACCOUNTS = "7",
  CARDS = "14",
  INTERNAL_TRANSFERS = "2",
  TRANSFERS = "8",
  CURRENCY_EXCHANGE = "5",
  COMISSION = "15",
  EXTERNAL_TRANSFERS = "3",
  CARD_ORDERS = "10",
  POS = "16",
  E_COMMERCE = "17",
}

export enum EAuth {
  LOGIN = "1",
  REFRESH = "2",
}

export enum EAccounts {
  INFO = "1",
  STATEMENT = "2",
}

export enum ECards {
  INFO = "1",
  STATEMENT = "2",
}

export enum EInternalTransfers {
  INLAND = "1",
  BANK_CODES = "2",
  STATE = "3",
  VAT = "4",
  BUDGET_LEVEL_CODES = "5",
  BUDGET_CODES = "6",
  BULK = "7",
  CANCELLATION = "8",
  A2C = "9",
}

export enum ETransfers {
  SALARY = "1",
  STATE = "2",
}

export enum ECurrencyExchange {
  CURRENCY_EXCHANGE = "1",
}

export enum EComission {
  COMISSION = "1",
}

export enum EExternalTransfers {
  EXTERNAL = "1",
  SWIFT_CODE_LIST = "2",
}

export enum ECardOrders {
  SALARY = "1",
  BRANCH_LIST = "2",
  STATEMENT = "3",
  GET_INFO = "4",
}

export enum EPos {
  INFO = "1",
  STATEMENT = "2",
}

export enum EECOMMERCE {
  INFO = "1",
}
