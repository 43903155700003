import { Components } from "@mui/material";

import { colorPalette } from "./colorPalette";

export const buttonTheme: Components = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 6,
        backgroundColor: colorPalette.danger.main,
        color: colorPalette.common.white,
        textTransform: "unset",
        height: 50,
        fontWeight: 600,
        fontSize: 14,
        padding: "24px 13px",

        "&:hover": {
          backgroundColor: colorPalette.danger.dark,
        },

        "&:disabled": {
          color: colorPalette.common.white,
          opacity: 0.5,
          cursor: "not-allowed",
        },
      },
      outlined: {
        backgroundColor: colorPalette.common.white,
        color: colorPalette.text.primary,
        border: "1px solid #CACCD0",

        "&:hover": {
          backgroundColor: colorPalette.common.white,
          background: colorPalette.secondary.main,
          border: "1px solid #CACCD0",
        },
      },
    },
  },
};
