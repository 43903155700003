import { FC } from "react";
import { Box } from "@mui/material";

import Img from "components/Img";

import "./index.scss";

const Loading: FC = () => {
  return (
    <Box className="loading">
      <div>
        <Img imgName={"loading.svg"} />
      </div>
    </Box>
  );
};

export default Loading;
