interface IUrls {
  local: string;
  development: string;
  qa: string;
  preprod: string;
  production: string;
}

const urls: IUrls = {
  local: "http://localhost:8083/api/portal",
  development: "https://dev.cb.kapitalbank.az/api/portal",
  qa: "https://qa-cb.kapitalbank.az/api/portal",
  preprod: "https://pre-cb.kapitalbank.az/api/portal",
  production: "https://cb.kapitalbank.az/api/portal",
};

export const defaultHeaders = {
  "Content-type": "application/json",
  "Accept-Language": "az",
};

export const env: keyof IUrls = (process.env.REACT_APP_BUILD_ENV as keyof IUrls) ?? Object.keys(urls)[1];
const apiBaseUrl: string = urls[env];

export const API_BASE_URL = apiBaseUrl;
