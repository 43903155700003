import { Components } from "@mui/material";

export const textField: Components = {
  MuiTextField: {
    styleOverrides: {
      root: {
        borderRadius: "10px",
      },
    },
  },
  MuiInputBase: {
    defaultProps: {},
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
    },
  },
};
