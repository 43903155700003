const PRODUCTS_PATH = "/products";

export const ROUTES = {
  MAIN: {
    PATH: "/",
  },
  HOME: {
    PATH: "/",
  },
  PRODUCTS: {
    PATH: PRODUCTS_PATH,
    PRODUCT: {
      PATH: `${PRODUCTS_PATH}/:id/:subId?`,
    },
  },
  FAQ: {
    PATH: "/faq",
  },
  GETAPI: {
    PATH: "/getapi",
  },
  HOW_TO_USE: {
    PATH: "/how-to-use",
  },
};
