import { Components } from "@mui/material";

import { colorPalette } from "./colorPalette";

export const dialogTheme: Components = {
  MuiDialog: {
    defaultProps: {
      fullWidth: true,
      maxWidth: "xs",
    },
    styleOverrides: {
      root: {
        borderRadius: 8,

        "& .MuiButton-close": {
          color: colorPalette.text?.primary,
          position: "absolute",
          top: 16,
          right: 16,
        },
      },
      paper: {
        borderRadius: 8,

        "&.MuiDialog-paper": {
          maxWidth: 600,
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {},
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        paddingTop: "28px",
        borderTop: `1px solid ${colorPalette.grey?.A200}`,

        "& > :not(:first-of-type)": {
          marginLeft: 12,
        },
      },
    },
  },
};
