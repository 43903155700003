export const colorPalette = {
  common: {
    white: "#ffffff",
    black: "#000000",
  },
  primary: {
    light: "#933e56",
    main: "#000",
    dark: "#600c24",
  },
  secondary: {
    light: "#696e76",
    main: "#F9F9F9",
    dark: "#363b43",
  },
  error: {
    main: "#DF3A4C",
    dark: "#FF4747",
    contrastText: "#fff",
  },
  success: {
    light: "#89E6AE",
    main: "#2CA83A",
    dark: "#24A859",
    contrastText: "#fff",
  },
  warning: {
    light: "#FACE75",
    main: "#E5AD1C",
    dark: "#D99308",
    contrastText: "#fff",
  },
  danger: {
    light: "#EC33421A",
    main: "#009FF7",
    dark: "#007fc5",
    contrasText: "",
  },
  info: {
    light: "#7AB9E1",
    main: "#419CD4",
    dark: "#2A81B7",
  },
  text: {
    success: "#2AC767",
    danger: "#009FF7",
    primary: "#25282B",
    main: "#333538",
  },
  grey: {
    A100: "#ECECEC",
    A200: "#E5E5E5",
    A400: "#C4C4C4",
    A700: "#BBBBBB",
  },
};
