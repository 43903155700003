export default {
  Menu: {
    home: "Главная",
    products: "Продукты API",
    usage: "Правила использования",
    faq: "FAQ",
  },
  Home: {
    IntroSlider: {
      Slide1: {
        title: "Теперь банк и бухгалтерия - в одном пространстве!",
        text: "Проводите банковские операции внутри вашей бухгалтерской системы",
      },
      Slide2: {
        title: "Удобный, быстрый и безопасный процесс!",
        text: "Пользуйтесь банковскими услугами в своем программном обеспечении",
      },
      Slide3: {
        title: "Начните новую эру в банкинге вместе с нами",
        text: "Вы можете предложить своим клиентам новые бизнес-решения",
      },
    },
    Usage: {
      title: "Простая процедура подключения",
      text: "Достаточно лишь зарегистрироваться в API-портале, чтобы использовать продукты",
      step1: {
        title: "Раскройте для себя новые возможности",
        description: "У нас большой выбор продуктов API",
      },
      step2: {
        title: "Опробуйте наши продукты в деле",
        description: "Потестируйте необходимые вам функции",
      },
      step3: {
        title: "Услуга предоставляется бесплатно",
        description: "Зарегистрируйтесь и подпишите контракт",
      },
      step4: {
        title: "Выбирайте и пользуйтесь",
        description: "Наши API к вашим услугам",
      },
    },
    Faq: {
      title: "Часто задаваемые вопросы",
      whatIsApi: {
        question: "Что такое API?",
        answer:
          "API (Application Programming Interface — «программный интерфейс приложения») — это техническая возможность, используемая для подготовки программного обеспечения или облегчения взаимодействия между различными не связанными друг с другом системами.",
      },
      purposeOfApi: {
        question: "Зачем мы создали портал API?",
        answer:
          "Цель портала — минимизировать рутинную рабочую нагрузку наших бизнес-клиентов. При помощи API вы можете безопасно и удобно управлять банковскими транзакциями прямо через собственное программное обеспечение.",
      },
    },
    Advantages: {
      title: "Почему именно Kapital Bank?",
      text: "Мы предоставляем Вашему бизнесу готовую платформу для удобного и быстрого управления счетами в одной программе.",
      free: {
        title: "Бесплатно",
        description: "Вы получаете любой продукт API совершенно бесплатно, без какой-либо платы за обслуживание",
      },
      modern: {
        title: "Актуально",
        description: "Наш API-портал создан с использованием лучшего мирового опыта в данной области",
      },
      safe: {
        title: "Безопасно",
        description: "Все проводимые в системе операции гарантированно и надёжно защищены",
      },
      comfortable: {
        title: "Удобно",
        description: "Интеграция между Kapital Bank и вашим бизнесом - простой и быстрый процесс",
      },
    },
  },
  Products: {
    title: "Откройте для себя наши продукты API!",
    text: "Предлагаемые решения упрощают и ускоряют работу как для вас, так и для ваших клиентов",
    PublishStatus: {
      ACTIVE: "Активно",
      SOON: "Скоро",
    },
    Auth: {
      title: "Авторизация",
      description:
        "Через наш API вы можете получить токен, соответствующий вашему имени пользователя, и начать использовать наши продукты.",
      Login: {
        title: "Login",
        description: "Ознакомьтесь с параметрами, которые нужно заполнить для авторизации в портале.",
      },
      Refresh: {
        title: "Refresh",
        description: "Ознакомьтесь с параметрами, которые нужно заполнить для процесса обновления.",
      },
    },
    Accounts: {
      title: "Информация о счетах",
      description: "При помощи этого API вы можете получить доступ к информации о своих счетах в Kapital Bank.",
      Info: {
        title: "Информация о текущем счете",
        description: "С помощью этого API вы можете получить информацию о ваших текущих счетах в Kapital Bank.",
      },
      Statement: {
        title: "Выписка по текущему счету",
        description: "С помощью этого API вы можете получить выписку со своих текущих счетов Kapital bank.",
      },
    },
    Cards: {
      title: "Информация о картах",
      description: "С помощью этого API можно получить данные о ваших картах в Kapital Bank.",
      Info: {
        title: "Бизнес карта",
        description: "С помощью этого API вы можете получить данные о бизнес карте в Kapital bank.",
      },
      Statement: {
        title: "Выписка из бизнес карты",
        description: "С помощью этого API вы можете получить выписку по бизнес карте в Kapital bank.",
      },
    },
    InternalTransfers: {
      title: "Переводы в национальной валюте",
      description: "Данный API позволяет осуществлять денежные переводы в национальной валюте через Kapital bank.",
      Inland: {
        title: "Денежные переводы внутри страны",
        description:
          "Вы можете использовать этот API для осуществления  переводов в национальной валюте на счета Kapital Bank или счета в других банках страны.",
      },
      Bulk: {
        title: "Массовые денежные переводы",
        description:
          "Вы можете использовать этот API для осуществления  переводов в национальной валюте на счета Kapital Bank или счета в других банках страны (Количество счетов-получателей для массового перевода - макс. 50).",
      },
      BankCodes: {
        title: "Список банковских кодов",
        description:
          "С помощью этого API вы можете получить список банковских кодов, используемых для определения банка получателя при переводах в национальной валюте.",
      },
      State: {
        title: "Статус переводов",
        description: "При помощи данного API вы можете узнать статус осуществленных денежных переводов.",
      },
      BudgetLevelCodes: {
        title: "Список кодов уровня бюджета",
        description:
          "С помощью этого API вы можете получить список кодов уровня бюджета, которые будут использоваться при переносе бюджета.",
      },
      BudgetCodes: {
        title: "Список кодов бюджетной классификации",
        description:
          "С помощью этого API вы можете получить список кодов бюджетной классификации, которые будут использоваться при переносе бюджета.",
      },
      Cancelation: {
        title: "Отмена перевода",
        description: "При помощи данного API вы можете отменить операцию по переводу.",
      },
    },
    Transfers: {
      title: "Пополнение карты",
      description: "С помощью этого API вы можете пополнять зарплатные карты.",
      Salary: {
        title: "Пополнение зарплатных карт",
        description:
          "Вы можете использовать этот API для пополнения баланса зарплатных карт, принадлежащих вашей организации.",
      },
      State: {
        title: "Статус пополнения",
        description: "При помощи данного API вы можете узнать статус операций по пополнению карт",
      },
    },
    Comission: {
      title: "Расчет комиссионного сбора",
      description: "Вы можете рассчитать любую комиссию через данный API.",
    },
    ExternalTransfers: {
      title: "Переводы в иностранной валюте",
      description: "Данный API позволяет осуществлять денежные переводы в иностранной валюте через Kapital bank.",
      External: {
        title: "Международные денежные переводы",
        description:
          "Вы можете использовать этот API для осуществления переводов в иностранной валюте со счетов Kapital Bank.",
      },
      SwiftCodeList: {
        title: "Список SWIFT кодов",
        description:
          "С помощью этого API вы можете получить список SWIFT кодов, которые будут использоваться при переводах.",
      },
    },
    CurrencyExchange: {
      title: "Безналичный обмен валюты",
      description: "При помощи данного API можно совершать операции по  безналичному обмену валюты.",
    },
    CardOrders: {
      title: "Заказ карт",
      description: "При помощи данного API вы можете заказать зарплатные карты через своё программное обеспечение.",
      Salary: {
        title: "Заказ зарплатных карт",
        description: "Используйте этот API для заказа зарплатных карт для ваших сотрудников",
      },
      BranchList: {
        title: "Список филиалов",
        description: "С этим API вы можете получить коды наших филиалов",
      },
      State: {
        title: "Статус заказа",
        description: "При помощи данного API вы можете узнать статус заказов.",
      },
      GetInfo: {
        title: "Данные карты",
        description: "Используя данный API, вы можете получить информацию о данных карты.",
      },
    },
    PosInfo: {
      title: "Информация о POS",
      description: "Используя предоставленные API, вы можете получить сведения и выписки об используемых POS.",
      Info: {
        title: "Сведения о POS",
        description: "Вы можете использовать этот API для получения информации об используемых POS.",
      },
      Statement: {
        title: "Выписка из POS",
        description: "Вы можете использовать этот API для получения выписки из используемых POS.",
      },
    },
    ECommerce: {
      title: "Информация электронной коммерции",
      description: "Вы можете получать отчеты о транзакциях электронной торговли через предоставленные API.",
    },
    Vat: {
      title: "Оплата 18% НДС",
      description:
        "Вы можете использовать этот API для оплаты 18% НДС при переводах между счетами в национальной валюте на счета Kapital Bank или других банков страны.",
    },
    A2c: {
      title: "Перевод со счёта на бизнес-карту",
      description: "Вы можете использовать этот API для пополнения бизнес-карт вашей компании.",
    },
    Fields: {
      id: "ID",
      type: "Тип",
      code: "Код",
      key: "Ключ",
      name: "Имя",
      number: "Hомер",
      desc: "Описание",
      value: "Значение",
      parameter: "Параметр",
      exampleFile: "Образец",
      required: "Обязательно",
      fileSignWithAsan: "Файл для подписания с ASAN İmza",
    },
  },
  ProductDetails: {
    inputParameters: "Входные параметры",
    outputParameters: "Выходные параметры",
    requestHeader: "Заголовок запроса",
    requestBody: "Тело запроса",
    operationTypes: "Типы операций",
    transferPurpose: "Назначение перевода",
    destinationList: "Список назначений",
    queryParams: "Параметры запроса",
    response: "Ответ",
    responseCodes: "Коды ответа",
    endpoint: "Адрес запроса",
    description: {
      //#region Authorization
      username: "Имя пользователя",
      password: "Пароль",
      userInfo: "Имя (user info)",
      surname: "Фамилия",
      signatureLevel: "Уровень подписи",
      clientInfo: "Имя (client info)",
      email: "Эл. почта",
      contactNumber: "Контактный номер",
      companyName: "Название компании",
      uniqueBankNumber: "Уникальный номер банка клиента",
      specialApproach: "Для особых случаев",
      customerId: "Пользовательский ID",
      jwtrefreshtoken: "jwtrefreshtoken",
      jwtoken: "jwtoken",
      signatureType: "Тип подписи",
      //#endregion

      //#region Account information
      name: "Имя",
      branchCode: "Код филиала",
      accountNumber: "Номер счета",
      ibanAccountNumber: "IBAN - номер счета",
      currency: "Валюта",
      accountType: "Тип аккаунта",
      accountStatus: "Статус аккаунта",
      remainingBalance: "Остаточный баланс после операции",
      currentAmount: "Текущее количество",
      blockedAmount: "Заблокированная сумма",
      beginningDate: "Дата начала",
      endingDate: "Дата окончания",
      clientName: "Имя клиента",
      accountCurrency: "Валюта счета",
      ibanOfAccount: "IBAN счета",
      beginningBalanceForeign: "Остаток на начало (иностранная валюта)",
      endBalanceForeign: "Остаток на конец (иностранная валюта)",
      totalReturnDebit: "Общий оборот (дебет)",
      totalReturnCredit: "Общий оборот (кредит)",
      beginningPeriod: "За период (начало)",
      endPeriod: "За период (конец)",
      taxId: "VÖEN",
      branchName: "Название филиала",
      beginningBalanceAzn: "Остаток на начало (AZN)",
      endBalanceAzn: "Остаток на конец (AZN)",
      totalReturnDebitAzn: "Общий оборот дебета (AZN)",
      totalReturnCreditAzn: "Общий оборот кредита (AZN)",
      documentNumber: "Номер документа",
      branch: "Филиал",
      direction: "Направление (дебет, кредит)",
      valueOfAmountAzn: "Значение суммы в AZN",
      amountForeign: "Сумма (иностранная валюта)",
      balanceForeign: "Баланс (иностранная валюта)",
      balanceAzn: "Баланс (значение в AZN)",
      history: "История",
      deletionDate: "Дата удаления",
      purpose: "Назначение",
      operationDate: "Дата операции",
      otherAccountInfo: "Имя, номер и ИНН обратного счёта",
      intrabankProductCode: "Внутрибанковский код продукта",
      // #endregion Account information

      //#region Card information
      serialNumber: "Порядковый номер",
      paymentCardType: "Тип платежной карты",
      cardNumber: "Номер карты",
      expirationDate: "Дата истечения срока",
      currentBalance: "Баланс",
      operationId: "ID операции",
      operationCode: "Код операции",
      operationDescription: "Описание операции",
      transactionAmount: "Сумма операции",
      transactionCurrencyCode: "Код валюты операции",
      transactionCurrency: "Валюта операции",
      transactionExecutionDate: "Дата исполнения операции",
      transactionRegistrationDate: "Дата регистрации операции",
      equivalentAmount: "Сумма (эквивалентная сумма)",
      equivalentCurrencyCode: "Код валюты (эквивалент)",
      recipientAccount: "Счёт получателя",
      senderAccount: "Счёт отправителя",
      salePointCode: "Код торговой точки",
      confirmationCode: "Код подтверждения",
      encryptedPaymentCardNumber: "Замаскированный номер платежной карты",
      //#endregion Card information

      //#region Transfers in national currency
      operationNumber: "Номер операции",
      beneficiaryBankCode: "Код банка бенефициара",
      recipientAccountNumber: "Номер счёта получателя",
      beneficiaryAccountTaxId: "VÖEN счёта получателя",
      recipientName: "Имя получателя",
      transferAmount: "Сумма перевода",
      purpose1: "Назначение 1",
      purpose2: "Назначение 2",
      state: "Статус",
      bankName: "Название банка",
      bankCode: "Код банка",
      bankTransactionId: "ID банковской операции",
      stateDescription: "Описание статуса",
      operationCreateDate: "Дата создания операции",
      budgetLevelCode: "Код уровня бюджета",
      desc: "Описание",
      budgetClassificationCode: "Код бюджетной классификации",
      vatOperationName: "Вводится номер операции внутреннего перевода.",
      vatAmount: "Вводится сумма перевода",
      vatPurpose: "Вводится назначение перевода",
      vatOperationId: "ID операции",
      vatOperationNameKey: "Номер операции",
      a2cOperationId: "ID операции",
      a2cFromAccount: "Номер счёта, с которого будут сняты средства",
      a2cToAccount: "Номер счёта, на который будут переведены средства",
      a2cAmount: "Сумма",
      a2cPurpose: "Назначение",
      a2cFiles: "Документ, поясняющий причину перевода",
      a2cBudget: "Бюджет",
      a2cNonBudget: "Не бюджет",
      a2cRepresentation: "Представительство",
      a2cBusinessTrip: "Командировка",
      //#endregion

      //#region Transfers to cards
      senderAccountNumber: "Номер счета отправителя",
      noteOnPurpose: "Примечание по опции 'Другое' в пункте назначения",
      amount: "Сумма",
      packageNumber: "Номер пакета",
      bankId: "ID банка",
      companyCodeCard: "Код компании",
      customNoteMessage: "Если в назначении выбрано «другое», то примечание обязательно для заполнения",
      //#endregion

      //#region Currency exchange
      senderIban: "IBAN счета-отправителя",
      receiverIban: "IBAN счета-получателя",
      exchRate: "Курс валют",
      operationState: "Статус операции",
      //#endregion

      //#region Purpose list
      salary: "Зарплата",
      businessTrip: "Командировка",
      vacation: "Отпуск",
      scholarship: "Стипендия",
      financialAid: "Финансовая помощь",
      sickLeave: "Больничный",
      compensation: "Компенсация",
      bonus: "Премия",
      childSupport: "Алименты",
      rent: "Арендная плата",
      lastBill: "Расчёт",
      other: "Другое",
      //#endregion

      //#region External transfer
      recipientAddress: "Адрес получателя",
      swiftOrBankCode: "SWIFT или код банка",
      recipientBankName: "Название банка-получателя",
      swiftCode: "SWIFT код",
      //#endregion

      //#region Operation types
      inlandTransfer: "Переводы внутри страны",
      inlandTransferAbove: "Выше 40 000 AZN",
      inlandTransferUnder: "До 40 000 AZN",
      transfersBtwbranchesAzn: "Переводы между филиалами - нац.валюта (Kapital Bank)",
      transfersBtwbranchesForeign: "Переводы между филиалами - иностр.валюта (Kapital Bank)",
      budgetTransfers: "Бюджетные переводы",
      foreignTransfers: "Зарубежные переводы",
      buyingCurrency: "Покупка валюты",
      sellingCurrency: "Продажа валюты",
      cardReplenishment: "Пополнение карты",
      transferWithinBranch: "Переводы внутри филиала (Kapital Bank)",
      cardOrder: "Заказ карты",
      cardOrderOneYear: "1 год",
      cardOrderTwoYear: "2 года",
      urgentTransfers: "Срочные переводы (внутри страны)",
      salaryDesc:
        "Зарплата, Командировка, Отпуск, Стипендия, Материальная помощь, Больничный, Компенсация, Премия, Расчёт",
      otherDesc: "Алименты, Аренда, Другое",
      //#endregion

      //#region Comission
      comissionAmount: "Сумма комиссии",
      //#endregion

      //#region Card Order
      companyCode: "Код организации",
      note: "Примечание",
      expirationPeriod: "Срок годности карты",
      comissionAccountIban: "С какого счета списывается комиссия",
      receiverCardBranchCode: "Код филиала, откуда можно забрать карту",
      pinCode: "FIN код",
      dateOfBirth: "Дата рождения",
      postalCode: "Почтовый индекс",
      phoneNumber: "Номер телефона",
      address: "Адрес",
      orderState: "Статус заказа",
      totalAmountOfOperations: "Общее количество успешных и неуспешных операций",
      fullname: "Фамилия, имя, отчество владельца карты",
      batchDate: "Дата заказа карты",
      lastFourDigits: "Последние 4 цифры карты",
      cmsPackNo: "Номер пакета CMS",
      residenceAddress: "Адрес проживания",
      //#endregion

      //#region POS
      terminalId: "ID терминала",
      terminalAddress: "Адрес терминала",
      authDate: "Дата авторизации",
      totalAmount: "Итого",
      taksitCount: "Количество платежей в рассрочку",
      //#endregion

      //#region Response codes
      successful: "Успешно",
      methodNotAllowed: "Модель запроса (request model) выбрана неправильно",
      amountAboveZero: "Сумма должна быть больше 0",
      operationIdRequired: "Требуется указать ID операции",
      amountRequired: "Требуется указать сумму",
      currencyRequired: "Требуется указать валюту",
      unauthorized: "Требуется авторизация",
      forbidden: "Запрещенный",
      tokenInvalid: "Токен недействителен",
      tokenExpired: "Время токена истекло",
      errorOccured: "Произошла ошибка",
      wrongParameter: "Неверный параметр",
      notFound: "Не найдено",
      //#endregion

      //#region E-commerce
      installment: "Рассрочка",
      operation: "Тип операции",
      pan: "16-значный номер карты",
      sessionId: "Номер сеанса",
      terminal: "Терминал",
      paymentSource: "Источник операции",
      rowId: "Серийный номер транзакции",
      rrn: "Ссылочный номер транзакции",
      draw: "Номер страницы",
      recordsFiltered: "Количество отфильтрованных данных",
      recordsTotal: "Общее количество данных",
      totalPages: "Общее количество страниц",
      merchantId: "идентификатор продавца",
      orderId: "номер заказа",
      page: "Страница",
      sort: "Сортировать",
      //#endregion

      //#region Validation messages
      validationMessages: {
        currencyExchange: {
          operationId: "макс. 20 символов",
          senderIban: "28 символов",
          receiverIban: "28 символов",
          amount: "может содержать цифры и точки",
          purpose: "не более 130 символов",
        },
        inlandTransfer: {
          senderAccount: "Макс/мин 28 символов (сочетание английских букв от A до Z и цифр от 0 до 9)",
          operationName: "макс. 20 символов. Обязательно к заполнению",
          beneficiaryBankCode: "макс/мин 6 символов",
          recipientAccountNumber: "макс/мин 28 символов (сочетание английских букв от A до Z и цифр от 0 до 9)",
          recipientTaxId: "макс/мин 10 символов",
          recipientName: "макс. 34 символа",
          transferAmount: "только цифры и точка или запятая, разделяющие цифры",
          purpose1: "макс. 64 символа, только английские буквы",
          purpose2: "макс. 64 символа, только английские буквы",
        },
        salary: {
          operationId: "макс. 20 символов",
          destinationList: "Список назначений указан ниже на этой странице",
          noteOnPurpose: "макс. 50 символов",
          recipientAccountNumber: "макс. 20 символов",
          recipientName: "макс. 150 символов",
          amount: "только цифры и точка или запятая, разделяющие цифры",
        },
        externalTransfers: {
          fromAccount: "28 символов - сочетание английских букв от A до Z и цифр от 0 до 9",
          toAccount: "28 символов - сочетание английских букв от A до Z и цифр от 0 до 9",
          amount: "может содержать цифры и точки",
        },
        cardOrder: {
          operationName: "макс. 20 символов. Обязательно к заполнению",
          companyCode: "мин/макс 12 символов",
          expirationPeriod: "может быть указан как 1, 2 или 3",
          branchCode: "3-значная цифра (код можно узнать в службе получения кодов филиалов)",
          pinCode: "7 символов, только английский шрифт",
          dateOfBirth: "в формате дд-мм-гггг",
          postalCode: "должен быть 4-значным",
          phoneNumber: "в формате 50xxxxxxx, мин/макс 9 цифр",
          emailFormat: "Email должен быть введён в правильном формате.",
        },
        vat: {
          amount: "если написано NULL, то автоматически рассчитывается и переводится 18% от основного перевода",
          purpose:
            "если написано NULL или пусто, то к номеру транзакции основного перевода добавляется фраза «EDV платеж»",
        },
        a2c: {
          operationId:
            "макс. 20 символов. Номер платежного поручения должен быть уникальным (последовательная очередь в течение дня). Формируется автоматически. Вы можете его изменить",
          fromAccount: "макс/мин. 20 символов",
          amount: "только цифры и разделяющие их точки или запятые",
          purpose: "только цифры 1, 2, 3, 4. Список назначений вы можете получить ниже",
        },
        maxLengthMessage: "макс. {{length}} символов",
        mixOfChars: "28 символов - сочетание английских букв от A до Z и цифр от 0 до 9",
      },
      //#endregion

      yes: "Да",
      no: "Нет",
      canBeEmpty: "Можно оставить пустым, если введён SWIFT",
      realEndpoint: "Реальный режим",
      testEndpoint: "Тестовая среда",
    },
  },
  Usage: {
    title: "Правила использования",
    text: "Вы можете интегрировать банковские услуги прямо в своё программное обеспечение через портал API.",
    howToUse: "Правила пользования порталом",
    usageIsGuide: "Вы можете интегрировать банковские услуги прямо в своё программное обеспечение через портал API.",
    Info: {
      title: "Информация о продуктах API",
      products:
        "Ознакомиться с API-продуктами, предоставляемыми банком, можно в разделе <0>«Продукты API»</0>. На первой странице представлен список существующих продуктов. <br/> • При нажатии на название любого из продуктов открывается страница с подробной информацией. <br/> • Меню слева содержит список доступных API для каждого продукта.",
      productInner:
        "При выборе определённого продукта API, будет отображено описание продукта, техническая информация про запросы <i>(input-output, request-response samples)</i>, коды ошибок, а также ссылка на конечную точку <i>(endpoint)</i>. Мы рекомендуем вам изучить эту информацию с сотрудниками технического отдела.",
    },
    Test: {
      title: "Протестировать продукты API",
      chooseTestSubject:
        "Если вы хотите опробовать API в тестовом режиме, то вам необходимо нажать на кнопку «Протестировать» на главной странице.",
      apply: "Заполните запрашиваемую информацию в открывшемся окне и отправьте заявку.",
      downloadTestInfo: "Загрузите файлы для тестирования",
    },
    Apply: {
      title: "Подать заявку на API",
      clickJoinFree: "Нажмите кнопку <0>«Подключиться бесплатно»</0>.",
      approvePin1:
        "• Введите мобильный номер и ID вашего ASAN İmza на открывшейся странице и подтвердите запрос кодом PIN1 на вашем мобильном.",
      continueInfoIsValid:
        "• На основании информации от ASAN İmza, вы сможете продолжить процесс, если являетесь <0>клиентом Kapital Bank, законным представителем  компании и пользователем в Birbank Biznes</0>.",
      chooseSignMethod:
        "На следующей странице вам нужно выбрать метод подписи транзакций. <br/>1. Подписание через Birbank Biznes: <br/>После выполнения транзакции в вашем программном обеспечении, вы можете подписать (ASAN İmza) или подтвердить транзакцию (одноразовый пароль или Google Auth), войдя в систему Birbank Biznes. <br/>2. Подписание в вашей системе: <br/>Подписав транзакцию в своей внутренней бухгалтерской системе с помощью ASAN İmza, вы можете отправить в банк конечный файл в формате ADOC прямо из своей системы.",
      reviewContract:
        "После завершения предыдущего этапа на основе ваших данных будет сформирован контракт об интеграции. Вы можете ознакомиться с контрактом, нажав на кнопку «Скачать».",
      approvePin2:
        "• Для подписания контракта нажмите кнопку «Подписать» и подтвердите запрос кодом PIN2 на вашем мобильном.",
      notificationAppear:
        "• На последней странице появится уведомление о том, что созданное для вас имя пользователя отправлено на ваш адрес электронной почты.",
    },
    Start: {
      title: "Всё готово",
      callEndpoints:
        "После прохождения вышеперечисленных этапов вы можете начать отправлять запросы на конечные точки (endpoints), расположенные на странице <0>«Продукты API»</0>. <br/>Обратите внимание, что во время финансовых транзакций (например, заказ карты, пополнение баланса и пр.) вы должны отправлять нам файлы, подписанные через ASAN İmza, или подтверждать проводимые в вашей внутренней системе операции в системе Birbank Biznes. <br/>Для других операций, носящих информационный характер (например, выписка со счёта, получение информации о карте и пр.) этого не требуется.<br/>С технической точки зрения процесс осуществляется таким образом: после ввода логина и пароля формируется <i>токен</i>, с помощью которого можно пользоваться услугами. Подробную техническую информацию можно получить, воспользовавшись продуктом API под названием <1>«Авторизация»</1>.",
    },
    Support: {
      title: "Мы всегда готовы оказать поддержку!",
      apply:
        "Ответы на частые вопросы вы можете найти в разделе FAQ. По другим возникшим вопросам вы легко можете связаться с нами через форму <0>«Поддержки»</0>, расположенную в правом нижнем углу сайта.",
      contactWithYou: "Мы свяжемся с вами в самое ближайшее время.",
    },
    BePartnerSupport: {
      title: "Процедура подачи заявки на партнёрство",
      clickToBePartner: "Выберите пункт «Запрос на партнёрство» в форме «Поддержки».",
      apply: "В открывшемся окне заполните необходимую информацию и нажмите кнопку «Отправить».",
      weWillContactYou: "Наши сотрудники свяжутся с вами в ближайшее время.",
    },
  },
  Faq: {
    title: "Часто задаваемые вопросы",
    text: "В данном разделе вы найдёте ответы на самые частые вопросы о портале API.",
    whatIsApi: {
      question: "Что такое API?",
      answer:
        "API (Application Programming Interface — «программный интерфейс приложения») — это техническая возможность, используемая для подготовки программного обеспечения или облегчения взаимодействия между различными не связанными друг с другом системами.<br/>Другими словами, API — это общий язык, который позволяет различным программным продуктам интегрироваться и работать вместе.<br/>Например, с помощью наших продуктов API, транзакции из вашей бухгалтерской программы будут отражаться непосредственно в банковской системе, и вы незамедлительно будете получать информацию о статусе транзакций.",
    },
    whatIsApiPortal: {
      question: "Что такое API-портал?",
      answer:
        "Порталом называется инструмент, отражающий информацию обо всех внедрённых API и последовательности интеграции.",
    },
    purposeOfApi: {
      question: "Зачем мы создали портал API?",
      answer:
        "Цель портала — минимизировать рутинную рабочую нагрузку наших бизнес-клиентов. При помощи API вы можете безопасно и удобно управлять банковскими транзакциями прямо через собственное программное обеспечение.",
    },
    howToTest: {
      question: "Как можно протестировать API?",
      answer:
        "Если вы хотите опробовать API в тестовом режиме, то вам необходимо перейти в раздел «Поддержки» и выбрать «Запрос на API для тестовой среды».",
    },
    isPaid: {
      question: "Взимается ли плата за использование портала API?",
      answer: "Нет. Наши пользователи могут пользоваться порталом и продуктами API совершенно бесплатно.",
    },
    whoCanUse: {
      question: "Кто может пользоваться порталом API?",
      answer:
        "Портал API предназначен для наших бизнес-клиентов. Для простоты взаимодействий с продуктами мы рекомендуем клиентам привлечь к работе с порталом программистов или сотрудников технического отдела.",
    },
    howToGet: {
      question: "Как получить API?",
      answer:
        "Главное условие для получения API — вы должны быть клиентом Kapital Bank.<br/>Нажав кнопку <1>«Подключиться бесплатно»</1> на главной странице, вы попадёте на страницу регистрации. На этой странице официальный представитель вашей компании подписывает соглашение, и тем самым ваш бизнес получает право на использование API.<br/>Для получения более подробной информации вы можете перейти на страницу <0>«Условия использования»</0>.",
    },
    whoCanSign: {
      question: "Кто имеет право подписывать контракт?",
      answer: "Контракт на интеграцию может подписать только официальный представитель компании через ASAN İmza.",
    },
    isMailValidForCb: {
      question: "Как получить доступ к системе Birbank Biznes?",
      answer:
        "Для упрощения процесса интеграции, сразу после регистрации на сайте Birbank Biznes мы высылаем на ваш адрес электронной почты ваш логин и пароль. С помощью данных логина и пароля вы сможете отправлять запросы прямо из вашего программного обеспечения, а также проводить операции в системе Birbank Biznes.",
    },
    howToResetPassword: {
      question: "Я забыл пароль, что делать?",
      answer: "Забытый или утерянный пароль легко можно восстановить на сайте Birbank Biznes <0>cb.kapitalbank.az</0>.",
    },
    hasAccesWithoutRegistration: {
      question: "Можно ли использовать API без регистрации в Birbank Biznes?",
      answer:
        "Если вы ещё не присоединились к Birbank Biznes, то вы можете пройти регистрацию на странице <0>onb-cb.kapitalbank.az</0>. Затем вы сможете приступить к использованию продуктов API с нашего портала.",
    },
    willOperationsAvailableOnCb: {
      question: "Будут ли мои транзакции отображаться в Birbank Biznes?",
      answer:
        "Да. Транзакции, которые вы совершаете через программное обеспечение вашей компании, также будут отражены в системе Birbank Biznes. Однако транзакции, которые вы совершаете только в системе Birbank Biznes, не будут отражены в вашем программном обеспечении.",
    },
    howToTestOnSelf: {
      question: "Как я могу протестировать API в своём программном обеспечении?",
      answer:
        "Вы можете тестировать наши продукты, используя конечные точки (endpoint), указанные в разделе «Продукты API», используя собственное программное обеспечение или определенные инструменты разработчика (например, Postman, Swagger и пр).",
    },
    howIsSecurity: {
      question: "Как обеспечивается безопасность процессов?",
      answer:
        "Безопасность портала основана на логике AUTH, все запросы поступают в нашу систему через зашифрованный токен.",
    },
    howToContact: {
      question: "Как я могу связаться с вами в случае возникновения вопросов?",
      answer:
        "Если у вас возникли вопросы или сложности, вы можете отправить нам свой запрос через раздел <0>«Поддержка»</0>, расположенный в правом нижнем углу этого сайта. Наши сотрудники свяжутся с вами в самое ближайшее время.",
    },
    willBeDiscount: {
      question: "Если я совершаю транзакцию со скидкой, будет ли скидка по-прежнему применена после использования API?",
      answer: "Да. Никаких изменений в тарифах не предусматривается.",
    },
    whoIsBePartnerFor: {
      question: "Для кого предназначен раздел «Запрос на партнёрство»?",
      answer:
        "Это удобный способ сотрудничества с Kapital Bank-ом для компаний, предлагающих бухгалтерские услуги. Благодаря интеграции продуктов API с  предлагаемыми вашей компанией бухгалтерскими программами, ваши клиенты смогут легко управлять своими операциями посредством всего одной программы.",
    },
  },
  Notification: {
    applyAccepted: "Запрос принят",
    error: "Ошибка",
    contractSigned: "Документ подписан",
    incorrectPhoneOrId: "Не установлен номер мобильного телефона или ID пользователя. Проверьте введённые данные",
    loginError: "Ошибка логина",
    certificateNotChoosen: "Сертификат не выбран",
    notResponse: "Нет ответа",
    thanksForRating: "Благодарим за вашу оценку!",
    ratingError: "Насколько вы довольны нашими услугами?",
  },
  JoinFree: {
    title: "Подключитесь к порталу бесплатно прямо сейчас!",
    Steps: {
      Info: {
        title: "Данные",
        howToGetAsanSign: "Как получить ASAN İmza?",
      },
      ChooseCertificate: {
        title: "Выбор сертификата",
      },
      SignFile: {
        title: "Подписание контракта",
        contract: "Контракт",
      },
      Pin: {
        title: "ASAN Imza код проверки",
        text: "Подтвердите запрос, отправленный на ваш номер",
      },
      Success: {
        title: "Проверьте почту!",
        text: "Данные о регистрации на портале API отправлены ​​на адрес электронной почты <1>{{userEmail}}</1>",
      },
      Error: {
        noAccess: "У вас нет прав на использование портала API. Свяжитесь с нами через форму «Поддержки».",
        noAccount: "У вас нет счета в Kapital Bank. Для открытия счёта перейдите по ссылке:",
        noCbUser: "Вы не являетесь клиентом Birbank Biznes. Свяжитесь с нами через форму «Поддержки».",
        noCertificates: "У вас нет сертификата для использования сервиса.",
        noVersionThree:
          "Мы подготовили новый Комплексный договор с общими условиями. Чтобы продолжить пользоваться услугами банка, пожалуйста, прочтите и подпишите этот договор.",
      },
      dearCustomer: "Уважаемый клиент",
      Fields: {
        AsanPhone: {
          label: "Мобильный номер ASAN İmza",
          placeholder: "Например: +994557040104",
        },
        UserId: {
          label: "ID пользователя",
          placeholder: "Например: 010586",
        },
        chooseCertificate: "Выбрать сертификат",
      },
    },
  },
  BePartner: {
    title: "Запрос на партнёрство",
    headerText: "Kapital Bank предлагает сотрудничество для компаний, предлагающих бухгалтерские услуги",
    ecomInfo: "Не предназначено для платформ электронной коммерции",
    Fields: {
      companyName: {
        label: "Название компании",
      },
      taxId: {
        label: "VÖEN",
      },
      companyOwner: {
        label: "Официальный представитель компании",
      },
      phone: {
        label: "Контактный номер телефона",
      },
      email: {
        label: "Адрес электронной почты",
      },
      note: {
        label: "Комментарии",
      },
    },
  },
  Soon: {
    title: "Узнавайте о новостях первыми!",
    text: "Укажите свою почту, чтобы подписаться на наши обновления.",
  },
  Action: {
    watchVideo: "Смотреть",
    joinFree: "Подключиться бесплатно",
    readMore: "больше",
    send: "Отправить",
    apply: "Отправить запрос",
    close: "Закрыть",
    backProducts: "Вернуться к продуктам",
    copied: "Скопировано!",
    continue: "Далее",
    notFound: "Не найдено",
    sign: "Подписать",
    view: "Скачать",
    backHome: "Вернуться на главную",
    support: "Служба поддержки",
    openAccount: "Открыть счёт",
    viewProducts: "Смотреть продукты API",
    startTest: "Протестировать",
    downloadFile: "Скачать",
    signContract: "Подписать договор",
  },
  Feedback: {
    giveFeedback: "Оцените нас",
    title: "Оцените нас",
    text: "Благодаря вашим отзывам, мы делаем наш сервис ещё лучше для вас",
    label: "Пожалуйста, поделитесь своим мнением:",
  },
  Support: {
    title: "Поддержка",
    text: "Выберите тему вашего обращения",
    Subjects: {
      Partner: {
        title: "Запрос на индивидуальный API",
        description: "Если вам нужен API с функциями, не представленными на сайте:",
        Note: {
          placeholder: "Краткое описание требуемых функций",
        },
      },
      Test: {
        title: "Запрос на API для тестовой среды",
        description: "Запросить имя пользователя и пароль для тестовой среды:",
        result: {
          description: "Детали для тестового режима",
        },
      },
      Real: {
        title: "Запрос на API для реального режима",
      },
      Ecom: {
        title: "Запрос для платформы e-commerce",
        info: "Портал Open API не предназначен для платформ электронной коммерции.",
        additionalInfo: "Вы можете обратиться за поддержкой по номеру <0>012 310 10 97</0>",
      },
      Other: {
        title: "Другое",
        description: "Мы ответим на любые ваши вопросы про API!",
      },
    },
    Success: {
      title: "Благодарим за обращение!",
      info: "Мы свяжемся с Вами в ближайшее время.",
    },
  },
  Form: {
    Fullname: {
      placeholder: "Имя и фамилия",
    },
    Phone: {
      placeholder: "Номер мобильного телефона",
    },
    Email: {
      placeholder: "Адрес электронной почты",
    },
    Taxid: {
      placeholder: "VÖEN",
    },
    Note: {
      placeholder: "Вопросы, пожелания, предложения",
    },
    yourEmail: "Адрес электронной почты",
    Error: {
      wrongLength: "Номер введён неверно",
      wrongFormat: "Неверный формат",
      taxIdFormat: "VÖEN должен состоять из 10 цифр",
    },
  },
  SignMethod: {
    title: "Метод подписи",
    CbSign: {
      title: "Подписание через Birbank Biznes",
      description:
        "После выполнения транзакции в вашем программном обеспечении, вы можете подписать (ASAN İmza) или подтвердить транзакцию (одноразовый пароль или Google Auth), войдя в систему  Birbank Biznes.",
    },
    SelfSign: {
      title: "Подписание в вашей системе",
      description:
        "Подписав транзакцию в своей внутренней бухгалтерской системе с помощью ASAN İmza, вы можете отправить в банк конечный файл в формате ADOC прямо из своей системы.",
    },
  },
  Footer: {
    copyright: "Copyright © {{currentYear}} Все права защищены",
  },
};
