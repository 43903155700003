import i18Next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { ELanguage } from "ts/enums/enums";

import az from "assets/locales/az-AZ";
import en from "assets/locales/en-EN";
import ru from "assets/locales/ru-RU";

const i18n = i18Next.createInstance();

i18n.use(LanguageDetector).init({
  react: {
    useSuspense: false,
  },
  detection: {
    lookupCookie: "OAi18next",
    lookupLocalStorage: "OAi18nextLng",
    order: ["localStorage", "cookie"],
    caches: ["localStorage", "cookie"],
  },
  fallbackLng: ELanguage.AZ,
  resources: {
    [ELanguage.EN]: en,
    [ELanguage.AZ]: az,
    [ELanguage.RU]: ru,
  },
  ns: ["common"],
});

export default i18n;
