import { createTheme } from "@mui/material/styles";

import { accordion } from "./accordion";
import { buttonTheme } from "./button";
import { colorPalette } from "./colorPalette";
import { dialogTheme } from "./dialog";
import { gridTheme } from "./grid";
import { selectTheme } from "./select";
import { tabTheme } from "./tab";
import { tableTheme } from "./table";
import { textField } from "./textField";
import { tooltipTheme } from "./tooltip";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 1024,
      lg: 1226,
      xl: 1536,
    },
  },
  palette: { ...colorPalette },
  components: {
    ...accordion,
    ...buttonTheme,
    ...gridTheme,
    ...dialogTheme,
    ...selectTheme,
    ...tableTheme,
    ...tabTheme,
    ...textField,
    ...tooltipTheme,
  },
});
