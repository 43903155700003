export default {
  Menu: {
    home: "Əsas səhifə",
    products: "API məhsullar",
    usage: "İstifadə qaydası",
    faq: "FAQ",
  },
  Home: {
    IntroSlider: {
      Slide1: {
        title: "Artıq bank və mühasibatlıq işləriniz bir arada!",
        text: "Mühasibatlıq sistemlərində bank xidmətlərini birbaşa icra edin",
      },
      Slide2: {
        title: "Rahat, sürətli və təhlükəsiz proses!",
        text: "Bank xidmətlərindən öz proqram təminatınızda faydalanın",
      },
      Slide3: {
        title: "Bank sektorunda yeni dövrə bizimlə başlayın",
        text: "Müştərilərinizə yeni biznes həllərini təklif edin",
      },
    },
    Usage: {
      title: "Rahat qoşulma qaydası",
      text: "Sadəcə açıq API portalına üzv olmaqla, təklif edilən bütün lazımi xidmətlərdən yararlana bilərsiniz!",
      step1: {
        title: "İmkanları kəşf edin",
        description: "Müxtəlif API məhsullarımızla tanış olun",
      },
      step2: {
        title: "Məhsullarımızı test edin",
        description: "Təqdim olunan funksiyaları sınayın",
      },
      step3: {
        title: "Tam ödənişsiz qoşulun",
        description: "Məlumatları doldurun, müqaviləni imzalayın",
      },
      step4: {
        title: "Artıq istifadəyə başlayın",
        description: "API-lar tam xidmətinizdədir",
      },
    },
    Faq: {
      title: "Ən çox verilən suallar",
      whatIsApi: {
        question: "API nədir?",
        answer:
          "API (Application Programming Interface – Tətbiq proqramlaşdırma interfeysi) – proqram təminatı hazırlamaq və ya fərqli sistemlər arasında qarşılıqlı əlaqəni asanlaşdırmaq üçün istifadə olunan texniki imkandır.",
      },
      purposeOfApi: {
        question: "API portalın məqsədi nədir?",
        answer:
          "Portalın məqsədi biznes müştərilərimizin iş yükünü minimuma endirməkdir. API vasitəsilə bank əməliyyatlarını öz proqram təminatınız üzərindən təhlükəsiz və rahat şəkildə idarə edə bilərsiniz.",
      },
    },
    Advantages: {
      title: "Niyə məhz Kapital Bank?",
      text: "Biznesinizi bir mənbədən idarə etmək üçün sizə mükəmməl platforma təqdim edirik",
      free: {
        title: "Ödənişsiz",
        description: "İstənilən API məhsulunu heç bir xidmət haqqı ödəmədən əldə edə bilərsiniz",
      },
      modern: {
        title: "Müasir",
        description: "Açıq API portalına qoşulmaqla, tətbiq etdiyimiz ən qabaqcıl həllərdən istifadə etmək imkanı",
      },
      safe: {
        title: "Təhlükəsiz",
        description: "Sistem daxili bütün proseslər və əməliyyatların təhlükəsizliyi qorunur",
      },
      comfortable: {
        title: "Rahat",
        description: "Kapital Bank-la biznesiniz arasında inteqrasiya rahat və asan şəkildə təmin edilir",
      },
    },
  },
  Products: {
    title: "API məhsullarımızı kəşf edin!",
    text: "Təklif etdiyimiz məhsullar həm sizin, həm müştərilərinizin işini asanlaşdırır və sürətləndirir",
    PublishStatus: {
      ACTIVE: "Aktiv",
      SOON: "Tezliklə",
    },
    Auth: {
      title: "Avtorizasiya",
      description:
        "Bizim API vasitəsilə istifadəçi adınıza uyğun token əldə edib, məhsullarımızdan yararlana bilərsiniz.",
      Login: {
        title: "Login",
        description: "Avtorizasiya mərhələsinə dair tələb olunan parametrlərlə buradan tanış olun.",
      },
      Refresh: {
        title: "Refresh",
        description: "Yenilənmə mərhələsinə dair tələb olunan parametrlərlə buradan tanış olun.",
      },
    },
    Accounts: {
      title: "Hesab məlumatları",
      description: "Bu API vasitəsi ilə Kapital Bank hesab məlumatlarınızı əldə edə bilərsiniz.",
      Info: {
        title: "Cari hesab məlumatları",
        description:
          "Cari hesab məlumatları API vasitəsi ilə siz Kapital Bank cari hesab məlumatlarınızı əldə edə bilərsiniz.",
      },
      Statement: {
        title: "Cari hesabdan çıxarış",
        description: "Bu API vasitəsi ilə siz Kapital Bank cari hesab məlumatlarınızı əldə edə bilərsiniz.",
      },
    },
    Cards: {
      title: "Kart məlumatları",
      description: "Bu API vasitəsi ilə siz Kapital Bank kart məlumatlarını əldə edə bilərsiniz.",
      Info: {
        title: "Biznes kart məlumatları",
        description: "Bu API vasitəsi ilə siz Kapital bank Biznes kart məlumatlarını əldə edə bilərsiniz.",
      },
      Statement: {
        title: "Biznes kartdan çıxarış",
        description: "Bu API vasitəsi ilə siz Kapital bank-ın biznes kart hesabından çıxarış əldə edə bilərsiniz.",
      },
    },
    InternalTransfers: {
      title: "Milli valyutada köçürmələr",
      description: "Bu API sizə Kapital bank vasitəsilə milli valyutada köçürmələr etməyə imkan yaradır.",
      Inland: {
        title: "Ölkədaxili köçürmələr",
        description:
          "Kapital Bank hesablarına və ya ölkənin digər banklarına milli valyutada hesablararası köçürmələr etmək üçün bu API-dan istifadə edə bilərsiniz.",
      },
      Bulk: {
        title: "Kütləvi ölkədaxili köçürmələr",
        description:
          "Milli valyutada hesablararası toplu şəkildə köçürmələr etmək üçün bu API-dan istifadə edə bilərsiniz (Kütləvi köçürmə üçün alan hesabların sayı - max. 50).",
      },
      BankCodes: {
        title: "Bank kodlarının siyahısı",
        description:
          "Ölkədaxili milli valyutada köçürmələr zamanı benefisiar bankı təyin etmək üçün istifadə edilən bank kodlarının siyahısını bu API vasitəsilə əldə edə bilərsiniz.",
      },
      State: {
        title: "Köçürmələrin statusu",
        description: "Təqdim edilən API vasitəsilə köçürmələrin statusunu əldə edə bilərsiniz.",
      },
      BudgetLevelCodes: {
        title: "Büdcə səviyyə kodlarının siyahısı",
        description:
          "Bu API vasitəsi ilə büdcə köçürmələri zamanı istifadə olunacaq büdcə səviyyə kodlarının siyahısını əldə edə bilərsiniz.",
      },
      BudgetCodes: {
        title: "Büdcə təsnifat kodlarının siyahısı",
        description:
          "Bu API vasitəsi ilə büdcə köçürmələri zamanı istifadə olunacaq büdcə təsnifat kodlarının siyahısını əldə edə bilərsiniz.",
      },
      Cancelation: {
        title: "Köçürmənin ləğvi",
        description: "Bu API vasitəsilə siz köçürmə əməliyyatını ləğv edə bilərsiniz.",
      },
    },
    Transfers: {
      title: "Kartlara mədaxil",
      description: "Bu API vasitəsi ilə siz əmək haqqı kartlarına köçürmələr edə bilərsiniz.",
      Salary: {
        title: "Əmək haqqı kartlarına mədaxil",
        description: "Şirkətinizə məxsus əmək haqqı kartlarına mədaxil üçün bu API-dan istifadə edə bilərsiniz.",
      },
      State: {
        title: "Mədaxilin statusu",
        description: "Bu API vasitəsilə siz mədaxilin statusunu əldə edə bilərsiniz.",
      },
    },
    Comission: {
      title: "Komissiya haqqının hesablanması",
      description: "Təqdim olunan API vasitəsilə komissiya haqqını hesablaya bilərsiniz.",
    },
    ExternalTransfers: {
      title: "Xarici valyutada köçürmələr",
      description: "Bu API sizə Kapital bank vasitəsilə xarici valyutada köçürmələr etməyə imkan yaradır.",
      External: {
        title: "Ölkəxarici köçürmələr",
        description:
          "Kapital Bank hesablarından xarici valyutada hesablararası köçürmələr etmək üçün bu API-dan istifadə edə bilərsiniz.",
      },
      SwiftCodeList: {
        title: "SWIFT kodlarının siyahısı",
        description:
          "Bu API vasitəsi ilə köçürmələr zamanı istifadə olunacaq SWIFT kodlarının siyahısını əldə edə bilərsiniz.",
      },
    },
    CurrencyExchange: {
      title: "Nağdsız valyuta mübadiləsi",
      description: "Bu API vasitəsilə nağdsız valyuta mübadiləsi əməliyyatlarını həyata keçirmək mümkündür.",
    },
    CardOrders: {
      title: "Kart sifarişləri",
      description: "Təqdim olunan API vasitəsilə artıq kartları birbaşa öz sisteminizdən sifariş edə bilərsiniz.",
      Salary: {
        title: "Əmək haqqı kart sifarişi",
        description: "Əməkdaşlarınız üçün kartları sifariş etmək üçün bu API-dan istifadə edin",
      },
      BranchList: {
        title: "Filialların siyahısı",
        description: "Bu API vasitəsilə filialların kodlarını əldə edə bilərsiniz",
      },
      State: {
        title: "Sifarişin statusu",
        description: "Təqdim edilən API vasitəsilə sifarişin statusunu əldə edə bilərsiniz.",
      },
      GetInfo: {
        title: "Kart məlumatlarının əldə olunması",
        description: "Təqdim olunan API vasitəsilə siz kart məlumatlarını əldə edə bilərsiniz.",
      },
    },
    PosInfo: {
      title: "POS məlumatları",
      description: "Təqdim olunan API-lar vasitəsilə POS məlumatlarını və çıxarışlarını əldə edə bilərsiniz.",
      Info: {
        title: "POS sorğuları",
        description: "Bu API vasitəsilə istifadə etdiyiniz POS-ların məlumatlarını əldə edə bilərsiniz.",
      },
      Statement: {
        title: "POS çıxarışları",
        description: "Bu API vasitəsilə istifadə etdiyiniz POS-ların çıxarışlarını əldə edə bilərsiniz.",
      },
    },
    ECommerce: {
      title: "E-ticarət məlumatları",
      description: "Təqdim olunan API-lar vasitəsilə E-ticarət əməliyyatlarının çıxarışlarını əldə edə bilərsiniz.",
    },
    Fields: {
      id: "ID",
      key: "Key",
      name: "Ad",
      type: "Tip",
      code: "Kod",
      desc: "İzah",
      value: "Dəyər",
      number: "Nömrə",
      parameter: "Parametr",
      required: "Məcburi xana",
      exampleFile: "Nümunə fayl",
      fileSignWithAsan: "ASAN İmza ilə imzalanacaq fayl",
    },
    Vat: {
      title: "18% ƏDV ödənişi",
      description:
        "Kapital Bank hesablarına və ya ölkənin digər banklarına milli valyutada hesablararası köçürmələr zamanı 18% ƏDV ödənişini etmək üçün bu API-dan istifadə edə bilərsiniz.",
    },
    A2c: {
      title: "Hesabdan biznes karta köçürmə",
      description: "Şirkətinizə məxsus biznes kartlara mədaxil etmək üçün bu API-dan istifadə edə bilərsiniz.",
    },
  },
  ProductDetails: {
    inputParameters: "Giriş parametrləri",
    outputParameters: "Çıxış parametrləri",
    requestHeader: "Sorğu başlığı",
    requestBody: "Sorğu mətni",
    operationTypes: "Əməliyyat tipləri",
    transferPurpose: "Köçürmə təyinatı",
    destinationList: "Təyinat siyahısı",
    queryParams: "Sorğu parametrləri",
    response: "Cavab",
    responseCodes: "Cavab kodları",
    endpoint: "Sorğu ünvanı",
    description: {
      //#region Authorization
      username: "İstifadəçi adı",
      password: "Şifrə",
      userInfo: "Ad (user info)",
      surname: "Soyad",
      signatureLevel: "İmza səviyyəsi",
      clientInfo: "Ad (client info)",
      email: "Email ünvanı",
      contactNumber: "Əlaqə nömrəsi",
      companyName: "Şirkətin adı",
      uniqueBankNumber: "Müştərinin unikal bank nömrəsi",
      specialApproach: "Xüsusi yanaşmalar üçün",
      customerId: "Müştəri ID-si",
      jwtrefreshtoken: "jwtrefreshtoken",
      jwtoken: "jwttoken",
      signatureType: "İmza növü",
      //#endregion

      //#region Account information
      name: "Ad",
      branchCode: "Filial kodu",
      accountNumber: "Hesab nömrəsi",
      ibanAccountNumber: "IBAN - hesab nömrəsi",
      currency: "Valyuta",
      accountType: "Hesab növü",
      accountStatus: "Hesabın statusu",
      remainingBalance: "Əməliyyatdan sonrakı qalıq balans",
      currentAmount: "Cari məbləğ",
      blockedAmount: "Bloklanmış məbləğ",
      beginningDate: "Başlanğıc tarixi",
      endingDate: "Son tarix",
      clientName: "Müştərinin adı",
      accountCurrency: "Hesabın valyutası",
      ibanOfAccount: "Hesabın IBAN-ı",
      beginningBalanceForeign: "İlkin qalıq (xarici valyuta)",
      endBalanceForeign: "Son qalıq (xarici valyuta)",
      totalReturnDebit: "Cəmi dövriyyələr (debit)",
      totalReturnCredit: "Cəmi dövriyyələr (kredit)",
      beginningPeriod: "Dövr üçün (başlanğıc)",
      endPeriod: "Dövr üçün (son)",
      taxId: "VÖEN",
      branchName: "Filial adı",
      beginningBalanceAzn: "İlkin qalıq (AZN)",
      endBalanceAzn: "Son qalıq (AZN)",
      totalReturnDebitAzn: "Cəmi dövriyyələr debit (AZN)",
      totalReturnCreditAzn: "Cəmi dövriyyələr kredit (AZN)",
      documentNumber: "Sənədin nömrəsi",
      branch: "Filial",
      direction: "İstiqamət (debit, kredit)",
      valueOfAmountAzn: "Məbləğin AZN dəyəri",
      amountForeign: "Məbləğ (xarici valyuta)",
      balanceForeign: "Balans (xarici valyuta)",
      balanceAzn: "Balans (AZN dəyəri)",
      history: "Tarix",
      deletionDate: "Silinmə tarixi",
      purpose: "Təyinat",
      operationDate: "Əməliyyatın tarixi",
      otherAccountInfo: "Əks hesabın nömrəsi, adı və VÖEN",
      intrabankProductCode: "Bankdaxili məhsul kodu",
      // #endregion Account information

      //#region Card information
      serialNumber: "Sıra nömrəsi",
      paymentCardType: "Ödəniş kartının tipi",
      cardNumber: "Kart nömrəsi",
      expirationDate: "Son istifadə tarixi",
      currentBalance: "Mövcud balans",
      operationId: "Əməliyyat ID-si",
      operationCode: "Əməliyyat kodu",
      operationDescription: "Əməliyyatın təsviri",
      transactionAmount: "Əməliyyatın məbləği",
      transactionCurrencyCode: "Əməliyyatın valyuta kodu",
      transactionCurrency: "Əməliyyatın valyutası",
      transactionExecutionDate: "Əməliyyatın icra tarixi",
      transactionRegistrationDate: "Əməliyyatın sənədə düşmə tarixi",
      equivalentAmount: "Məbləğ (ekvivalent məbləğ)",
      equivalentCurrencyCode: "Valyuta kodu (ekvivalent)",
      recipientAccount: "Alan hesab",
      senderAccount: "Göndərən hesab",
      salePointCode: "Ticarət nöqtəsinin kodu",
      confirmationCode: "Təsdiq kodu",
      encryptedPaymentCardNumber: "Maskalanmış ödəniş kart nömrəsi",
      //#endregion Card information

      //#region Transfers in national currency
      operationNumber: "Əməliyyat nömrəsi",
      beneficiaryBankCode: "Benefisiar bank kodu",
      recipientAccountNumber: "Alanın hesab nömrəsi",
      beneficiaryAccountTaxId: "Alan hesabın VÖEN-i",
      recipientName: "Alan müştərinin adı",
      transferAmount: "Köçürmə məbləği",
      purpose1: "Təyinat 1",
      purpose2: "Təyinat 2",
      state: "Status",
      bankName: "Bankın adı",
      bankCode: "Bank kodu",
      bankTransactionId: "Bank əməliyyat ID-si",
      stateDescription: "Status izahı",
      operationCreateDate: "Əməliyyatın yaranma tarixi",
      budgetLevelCode: "Büdcə səviyyə kodu",
      desc: "Təsvir",
      budgetClassificationCode: "Büdcə təsnifat kodu",
      vatOperationName: "Ölkədaxili köçürmə əməliyyatının nömrəsi daxil edilir.",
      vatAmount: "Köçürələcək məbləğ daxil edilir",
      vatPurpose: "Köçürmənin təyinatı daxil edilir",
      vatOperationId: "Əməliyyat id",
      vatOperationNameKey: "Əməliyyat nömrəsi",
      a2cOperationId: "Əməliyyat ID-si",
      a2cFromAccount: "Vəsaitin silinəcəyi hesab nömrəsi",
      a2cToAccount: "Vəsait köçürüləcək hesab nömrəsi",
      a2cAmount: "Məbləğ",
      a2cPurpose: "Təyinat",
      a2cFiles: "Köçürmənin səbəbini izah edən sənəd",
      a2cBudget: "Büdcə",
      a2cNonBudget: "Qeyri-büdcə",
      a2cRepresentation: "Nümayəndəlik",
      a2cBusinessTrip: "Ezamiyyət",
      a2cStatus: "Status",
      //#endregion

      //#region Transfers to cards
      senderAccountNumber: "Göndərənin hesab nömrəsi",
      noteOnPurpose: "Təyinatda 'Digər' seçim üzrə qeyd",
      amount: "Məbləğ",
      packageNumber: "Paket nömrəsi",
      bankId: "Bank ID-si",
      companyCodeCard: "Təşkilat kodu",
      customNoteMessage: "Təyinat 'digər' seçilərsə, qeyd xanası mütləq doldurulmalıdır",
      //#endregion

      //#region Currency exchange
      senderIban: "Satan hesabın IBAN-ı",
      receiverIban: "Alan hesabın IBAN-ı",
      exchRate: "Məzənnə",
      operationState: "Əməliyyatın statusu",
      //#endregion

      //#region Purpose list
      salary: "Əmək haqqı",
      businessTrip: "Ezamiyyət",
      vacation: "Məzuniyyət",
      scholarship: "Təqaüd",
      financialAid: "Maddi yardım",
      sickLeave: "Xəstəlik vərəqəsi",
      compensation: "Kompensasiya",
      bonus: "Mükafat",
      childSupport: "Aliment",
      rent: "İcarə haqqı",
      lastBill: "Son haqq-hesab",
      other: "Digər",
      //#endregion

      //#region External transfer
      recipientAddress: "Alanın ünvanı",
      swiftOrBankCode: "SWIFT və ya Bank kodu",
      recipientBankName: "Alan bankın adı",
      swiftCode: "SWIFT kodu",
      //#endregion

      //#region Operation types
      inlandTransfer: "Ölkədaxili köçürmələr",
      inlandTransferAbove: "40 000 AZN-dən yuxarı",
      inlandTransferUnder: "40 000 AZN-ə qədər",
      transfersBtwbranchesAzn: "Filiallararası köçürmələr - MV (Kapital Bank)",
      transfersBtwbranchesForeign: "Filiallararası köçürmələr - XV (Kapital Bank)",
      budgetTransfers: "Büdcə köçürmələri",
      foreignTransfers: "Ölkəxarici köçürmələr",
      buyingCurrency: "Valyuta alışı",
      sellingCurrency: "Valyuta satışı",
      cardReplenishment: "Karta mədaxil",
      transferWithinBranch: "Filialdaxili köçürmələr (Kapital Bank)",
      cardOrder: "Kart sifarişi",
      cardOrderOneYear: "1 illik",
      cardOrderTwoYear: "2 illik",
      urgentTransfers: "Təcili köçürmələr (ölkədaxili)",
      salaryDesc:
        "Əmək haqqı, Ezamiyyət, Məzuniyyət, Təqaüd, Maddi yardım, Xəstəlik vərəqəsi, Kompensasiya, Mükafat, Son haqq-hesab",
      otherDesc: "Aliment, İcarə haqqı, Digər",
      //#endregion

      //#region Comission
      comissionAmount: "Komissiya məbləği",
      //#endregion

      //#region Card Order
      companyCode: "Təşkilat kodu",
      note: "Qeyd",
      expirationPeriod: "Kartın müddəti",
      comissionAccountIban: "Xidmət haqqının tutulacağı hesab",
      receiverCardBranchCode: "Kartın təhvil alınacağı filial kodu",
      pinCode: "FİN kod",
      dateOfBirth: "Doğum tarixi",
      postalCode: "Poçt indeksi",
      phoneNumber: "Telefon nömrəsi",
      address: "Ünvan",
      orderState: "Sifarişin statusu",
      totalAmountOfOperations: "Ümumi uğurlu və uğursuz əməliyyatların sayı",
      fullname: "Kart sahibinin adı, soyadı, atasının adı",
      batchDate: "Sifarişin tarixi",
      lastFourDigits: "Kartın son 4 rəqəmi",
      cmsPackNo: "CMS paket nömrəsi",
      residenceAddress: "Yaşayış ünvanı",
      //#endregion

      //#region POS
      terminalId: "Terminal ID-si",
      terminalAddress: "Terminal ünvanı",
      authDate: "Avtorizasiya tarixi",
      totalAmount: "Ümumi məbləğ",
      taksitCount: "Bölünmüş taksit sayı",
      //#endregion

      //#region Response codes
      successful: "Uğurlu",
      methodNotAllowed: "Sorğu (request model) düzgün seçilməyib",
      amountAboveZero: "Məbləğ 0-dan böyük olmalıdır",
      operationIdRequired: "Əməliyyat ID-si tələb olunur",
      amountRequired: "Məbləğ tələb olunur",
      currencyRequired: "Valyuta tələb olunur",
      unauthorized: "Avtorizasiya tələb olunur",
      forbidden: "Qadağan",
      tokenInvalid: "Token yanlışdır",
      tokenExpired: "Tokenin vaxtı bitib",
      errorOccured: "Səhv baş verdi",
      wrongParameter: "Yanlış parametr",
      notFound: "Tapılmadı",
      //#endregion

      //#region E-commerce
      installment: "Taksit",
      operation: "Əməliyyat növü",
      pan: "16 rəqəmli kart nömrəsi",
      sessionId: "Sessiya nömrəsi",
      terminal: "Terminal",
      paymentSource: "Əməliyyatın mənbəyi",
      rowId: "Əməliyyatın sıra nömrəsi",
      rrn: "Əməliyyatın referans nömrəsi",
      draw: "Səhifənin nömrəsi",
      recordsFiltered: "Qayıdan məlumat sayı",
      recordsTotal: "Ümumi məlumat sayı",
      totalPages: "Ümumi səhifə sayı",
      merchantId: "Satıcı ID-si",
      orderId: "Sifarişin ID-si",
      page: "Default 0 olmalıdır",
      sort: "Nizamlamaq",
      //#endregion

      //#region Validation messages
      validationMessages: {
        currencyExchange: {
          operationId: "max. 20 simvol",
          senderIban: "28 simvol",
          receiverIban: "28 simvol",
          amount: "rəqəm ve nöqtədən ibarət ola bilər",
          purpose: "max 130 simvol",
        },
        inlandTransfer: {
          senderAccount: "Max/min 28 simvol (A-dan Z-ə qədər ingilis hərflərin və 0-dan 9-a qədər rəqəmlərin qarışığı)",
          operationName: "max. 20 simvol. Boş qalmamalıdır",
          beneficiaryBankCode: "max/min 6 simvol",
          recipientAccountNumber:
            "max/min uzunluq 28 simvoldur (A-dan Z-ə qədər ingilis hərflərin və 0-dan 9-a qədər rəqəmlərin qarışığı)",
          recipientTaxId: "max/min 10 simvol",
          recipientName: "max. 34 simvol",
          transferAmount: "yalnız rəqəm və rəqəmləri ayıran nöqtə və ya vergül",
          purpose1: "max. 64 simvol, yalnız ingilis hərfləri",
          purpose2: "max. 64 simvol, yalnız ingilis hərfləri",
        },
        salary: {
          operationId: "max. 20 simvol",
          destinationList: "Təyinat siyahısını aşağıdan əldə edə bilərsiniz",
          noteOnPurpose: "max. 50 simvol",
          recipientAccountNumber: "max. 20 simvol",
          recipientName: "max. 150 simvol",
          amount: "yalnız rəqəm və rəqəmləri ayıran nöqtə və ya vergül",
        },
        externalTransfers: {
          fromAccount: "28 simvol - A-dan Z-ə ingilis hərfləri, 0-dan 9-a qədər rəqəmlər",
          toAccount: "16-34 simvol - A-dan Z-ə ingilis hərfləri, 0-dan 9-a qədər rəqəmlər",
          amount: "rəqəm və nöqtədən ibarət ola bilər",
        },
        cardOrder: {
          operationName: "max. 20 simvol ibarət olmalıdır. Boş qalmamalıdır.",
          companyCode: "min/max 12 simvoldan ibarət olmalıdır",
          expirationPeriod: "1, 2 və ya 3 olaraq qeyd edilə bilər",
          branchCode: "3-rəqəmli olmalıdır (filial kodlarının əldə olunması servisindən əldə edə bilərsiniz)",
          pinCode: "7 simvoldan ibarət olmalıdır və yalnız ingilis şriftindən istifadə oluna bilər",
          dateOfBirth: "gg-aa-iiii formatında daxil edilə bilər",
          postalCode: "4-rəqəmli olmalıdır",
          phoneNumber: "50xxxxxxx formatında daxil edilməlidir, min/max 9 rəqəm daxil edilə bilər",
          emailFormat: "Email ünvanı düzgün email formatında olmalıdır.",
        },
        a2c: {
          operationId:
            "maks. 20 simvol ola bilər. Ödəniş tapşırığının nömrəsi unikal olmalıdır (köçürmə günü ərzində sıra ardıcıllığı ilə). Avtomatik generasiya olunur. Dəyişiklik edə bilərsiniz",
          fromAccount: "maks/min. 20 simvol",
          amount: "yalnız rəqəm və rəqəmləri ayıran nöqtə və ya vergül",
          purpose: "yalnız 1, 2, 3, 4 rəqəmləri. Təyinat siyahısını aşağıdan əldə edə bilərsiniz",
        },
        vat: {
          amount: "NULL yazıldığı təqdirdə əsas köçürmənin avtomatik 18%-i hesablanaraq köçürülür.",
          purpose:
            "NULL yazıldığı və ya boşluq olduğu təqdirdə əsas köçürmənin əməliyyat nömrəsinə “EDV odenishi” ifadəsi əlavə olunur",
        },
        maxLengthMessage: "max. {{length}} simvol",
        mixOfChars: "A-dan Z-ə qədər ingilis hərflərin və 0-dan 9-a qədər rəqəmlərin qarışığı",
      },
      //#endregion

      yes: "Bəli",
      no: "Xeyr",
      canBeEmpty: "SWIFT daxil edilibsə, boş qala bilər",
      realEndpoint: "Real rejim",
      testEndpoint: "Test mühiti",
    },
  },
  Usage: {
    title: "İstifadə qaydası",
    text: "API portal vasitəsi ilə bank xidmətlərini birbaşa öz proqram təminatınıza inteqrasiya edə bilərsiniz.",
    howToUse: "İstifadə haqda məlumat",
    usageIsGuide:
      "API portal vasitəsi ilə bank xidmətlərini birbaşa öz proqram təminatınıza inteqrasiya edə bilərsiniz.",
    Info: {
      title: "API-lar haqda məlumat",
      products:
        "<0>«API məhsullar»</0>  bölməsinə keçid edərək bankın tərəfindən təqdim edilən API məhsullarla tanış ola bilərsiniz. İlk səhifədə ümumi məhsulların siyahısı əks olunur. <br/> • Məhsullardan hər hansı birinin üzərinə klik etdikdə, ətraflı məlumat əks edilən səhifə açılır.; <br/> • Soldakı menyuda hər məhsula aid ətraflı API-ların siyahısı göstəriləcək.",
      productInner:
        "Seçimə uyğun olaraq, API məhsulunun izahı, sorğu-cavab texniki məlumatları <i> (input-output, request-response samples) </i>, xəta kodları və sonda API linki <i> (endpoint) </i> əks olunacaq. Bu səhifəni texniki şöbənin əməkdaşları ilə birlikdə nəzərdən keçirməyinizi tövsiyə edirik.",
    },
    Test: {
      title: "API-ları test edin",
      chooseTestSubject:
        "API-ları test rejimində sınaqdan keçirmək istəyirsinizsə, əsas səhifədə «Testə başla» düyməsini sıxın.",
      apply: "Açılan pəncərədə müvafiq məlumatları tamamlayıb, müraciət edə bilərsiniz.",
      downloadTestInfo: "Hazır olan faylları endirin",
    },
    Apply: {
      title: "API-lar üçün müraciət edin",
      clickJoinFree: "Başlanğıc üçün <0>«Ödənişsiz qoşul»</0> düyməsini basın.",
      approvePin1:
        "• Açılan səhifədə ASAN İmza nömrəsini və ID-ni daxil edərək, sorğunu mobil telefonda PIN1 kodu ilə təsdiq edin.",
      continueInfoIsValid:
        "• Asan İmza-dan gələn məlumatlar əsasında <0>Kapital Bank müştərisi, şirkətinizin qanuni təmsilçisi və Birbank Biznesdə istifadəçi</0> olduğunuz təqdirdə prosesi davam etdirə biləcəksiniz.",
      chooseSignMethod:
        "Növbəti səhifədə əməliyyatların imzalanma metodunu seçməlisiniz. <br/> 1. Birbank Biznes sistemində imzalama: <br/> Əməliyyatı öz proqram təminatınızda icra etdikdən sonra, onu imzalamaq (ASAN İmza) və ya təsdiqləmək (birdəfəlik şifrə və ya Google Auth) üçün Birbank Biznes sisteminə daxil olaraq, əməliyyatı təsdiqləyə bilərsiniz. <br/> 2. Daxili sisteminizdə imzalama: <br/>Əməliyyatı daxili mühasibatlıq sisteminizdə ASAN İmza vasitəsi ilə imzalayaraq, ADOC formatlı faylı proqram təminatınızdan birbaşa banka göndərə bilərsiniz.",
      reviewContract:
        "Bu mərhələni bitirdikdən sonra sizin məlumatlarınız əsasında inteqrasiya müqaviləsi formalaşacaq. «Endir» düyməsinə basaraq, bu müqaviləni nəzərdən keçirə bilərsiniz.",
      approvePin2:
        "• Müqaviləni imzalamaq üçün «İmzala» düyməsini basın və sorğunu mobil telefonda PIN2 kodu ilə təsdiq edin.",
      notificationAppear:
        "• Son səhifədə isə sizin üçün yaradılmış istifadəçi adının e-poçt ünvanınıza göndərildiyi barədə bildiriş əks olunacaq.",
    },
    Start: {
      title: "İstifadəyə başlayın",
      callEndpoints:
        "Yuxarıda sadalanan addımları yerinə yetirdikdən sonra <0>«API məhsullar»</0> səhifəsində yerləşən “endpoint”-ləri çağırmağa başlaya bilərsiniz. <br/> Nəzərə alın ki, maliyyə xarakterli əməliyyatlar zamanı (məsələn, kart sifarişi, karta mədaxil və s.) siz öz faylınızı ASAN İmza vasitəsilə imzalayaraq birbaşa bizə göndərməlisiniz və ya daxili sisteminizdə yaratdığınız əməliyyatı Birbank Biznesdə təsdiqləməlisiniz. <br/> Digər, məlumat xarakterli əməliyyatlarda isə (məsələn, kart hesabından çıxarış, kartdan məlumatın alınması və s.) buna ehtiyac yoxdur. <br/> Texniki baxımdan proses bu cür həyata keçirilir: istifadəçi adı və şifrə daxil olunduqdan sonra <i>token</i> əldə edilir – bu <i>token</i> vasitəsilə servislərdən istifadə edə bilərsiniz. Texniki detalları ətraflı şəkildə <1>«Avtorizasiya»</1> adlı API məhsulundan əldə edə bilərsiniz.",
    },
    Support: {
      title: "Hər zaman dəstəyə hazırıq!",
      apply:
        "Yaranan suallarla bağlı cavabları FAQ bölməsində tapa bilərsiniz. Digər suallarla bağlı aşağı sağ küncdə yerləşən <0>«Dəstək»</0> vasitəsilə bizə birbaşa müraciət edə bilərsiniz.",
      contactWithYou: "Ən qısa zamanda sizinlə əlaqə saxlayacağıq.",
    },
    BePartnerSupport: {
      title: "Partnyorluq üçün müraciət qaydası",
      clickToBePartner: "«Dəstək» formasında «Partnyor olmaq üçün müraciət» bəndini seçin.",
      apply: "Açılan pəncərədə tələb olunan məlumatları doldurub, «Müraciət et» düyməsinə basın.",
      weWillContactYou: "Əməkdaşlarımız ən qısa zamanda sizinlə əlaqə saxlayacaq.",
    },
  },
  Faq: {
    title: "Ən çox verilən suallar",
    text: "Açıq API portalı ilə bağlı yarana biləcək bir çox sualların cavabını bu bölmədə tapa bilərsiniz.",
    whatIsApi: {
      question: "API nədir?",
      answer:
        "API (Application Programming Interface – Tətbiq proqramlaşdırma interfeysi) – proqram təminatı hazırlamaq və ya fərqli sistemlər arasında qarşılıqlı əlaqəni asanlaşdırmaq üçün istifadə olunan texniki imkandır. <br/>Başqa sözlə, API – qarşılıqlı əlaqəsi olmayan, müxtəlif proqram təminatlarını bir-biri ilə inteqrasiya etməyə və birlikdə işləməsinə imkan verən ortaq dildir. <br/>Məsələn, təqdim etdiyimiz API-larla mühasibatlıq proqram təminatınızda icra etdiyiniz əməliyyatlar birbaşa bank sistemində əks olunacaq və siz dərhal əməliyyatların statusu barədə məlumat alacaqsınız.",
    },
    whatIsApiPortal: {
      question: "API portal nədir?",
      answer: "Açıq API-lar üzrə məlumatlar və inteqrasiya ardıcıllığı əks olunan vasitə portal adlandırılır.",
    },
    purposeOfApi: {
      question: "API portalın məqsədi nədir?",
      answer:
        "Portalın məqsədi biznes müştərilərimizin iş yükünü minimuma endirməkdir. API vasitəsilə bank əməliyyatlarını öz proqram təminatınız üzərindən təhlükəsiz və rahat şəkildə idarə edə bilərsiniz.",
    },
    howToTest: {
      question: "API-ları necə test edə bilərəm?",
      answer:
        "API-ları test etmək üçün «Dəstək» bölməsindən “Test mühitində API üçün müraciət” xanasını seçib, istifadəçi adı üçün müraciət edə bilərsiniz. Sizinlə ən qısa zaman ərzində əlaqə saxlanılacaq.",
    },
    isPaid: {
      question: "API portal ödənişlidir?",
      answer: "Xeyr. İstifadəçilərimiz API portaldan tamamilə ödənişsiz faydalana bilərlər.",
    },
    whoCanUse: {
      question: "API portaldan kim istifadə edə bilər?",
      answer:
        "API portal biznes müştərilərimiz üçün nəzərdə tutulub. Eyni zamanda, texniki detalların qeyd olunduğu bölməni proqramçılarla və ya texniki şöbənin əməkdaşları ilə birlikdə nəzərdən keçirməyiniz tövsiyə olunur.",
    },
    howToGet: {
      question: "API-ları necə əldə etmək olar?",
      answer:
        "API-ları əldə etmək üçün əsas şərt Kapital Bank-ın müştərisi olmaqdır. <br/>Əsas səhifədə «Ödənişsiz qoşul» düyməsinə basaraq, qeydiyyat səhifəsinə keçid edirsiniz. Həmin səhifədə rəsmi təmsilçi müqaviləni imzalayır və bununla biznesiniz API-dan istifadə hüququnu əldə edir. <br/>Daha ətraflı məlumat üçün «İstifadə qaydası» səhifəsinə keçid edə bilərsiniz.",
    },
    whoCanSign: {
      question: "Müqaviləni kim imzalaya bilər?",
      answer:
        "İnteqrasiya haqda müqavilə yalnız şirkətin rəsmi təmsilçisi tərəfindən ASAN İmza vasitəsilə imzalana bilər.",
    },
    isMailValidForCb: {
      question: "Birbank Biznes sisteminə necə giriş edim?",
      answer:
        "İnteqrasiyanın rahat keçməsi üçün, qeydiyyatdan sonra Birbank Biznesə aid mövcud istifadəçi adınızı və şifrəni e-poçt ünvanınıza göndəririk. İstifadəçi adı və şifrə vasitəsi ilə siz həm öz proqram təminatınızdan birbaşa sorğular göndərə biləcəksiniz, həm də Birbank Biznes sistemində əməliyyatları apara biləcəksiniz.",
    },
    howToResetPassword: {
      question: "Şifrəni unutmuşam, nə edim?",
      answer:
        "Şifrənizi unutduğunuz təqdirdə Birbank Biznes saytında <0>(cb.kapitalbank.az)</0> onu asanlıqla yeniliyə bilərsiniz.",
    },
    hasAccesWithoutRegistration: {
      question: "Birbank Biznesdə qeydiyyat olmadan API-lardan istifadə etmək olar?",
      answer:
        "Birbank Biznesdə qeydiyyatınız yoxdursa, ilk olaraq <0>onb-cb.kapitalbank.az</0> səhifəsinə daxil olun və qeydiyyatdan keçin. Sonra isə portaldan API-ları rahatlıqla əldə edib, istifadəyə başlaya bilərsiniz.",
    },
    willOperationsAvailableOnCb: {
      question: "Aparılan əməliyyatlar Birbank Biznesdə əks olunacaq?",
      answer:
        "Bəli. Şirkətin proqram təminatı vasitəsilə həyata keçirdiyiniz əməliyyatlar eyni zamanda Birbank Biznes sistemində də əks olunacaq. Lakin öz proqram təminatınızda deyil, yalnız Birbank Biznes sistemində icra etdiyiniz əməliyyatlar sizin proqram təminatınızda əks olunmayacaqdır.",
    },
    howToTestOnSelf: {
      question: "API-ları şirkətimin proqram təminatında necə test edə bilərəm?",
      answer:
        "«API məhsullar» bölməsində qeyd olunmuş son nöqtələrdən (endpoint) istifadə edərək, xidmətləri öz proqram təminatınızda və ya müəyyən proqramçı alətləri (məsələn, Postman, Swagger və s.) vasitəsilə məhsullarımızı test edə bilərsiniz.",
    },
    howIsSecurity: {
      question: "Təhlükəsizlik necə təmin edilir?",
      answer:
        "Təhlükəsizliyin təmin edilməsi AUTH məntiqinə əsaslanır və bütün sorğular bizim sistemə şifrələnmiş token vasitəsi ilə daxil olur.",
    },
    howToContact: {
      question: "Sual yaranarsa, sizinlə necə əlaqə saxlaya bilərəm?",
      answer:
        "Hər hansı sualınız və ya çətinliyiniz yaranarsa, aşağı sağ küncdə yerləşən <0>«Dəstək»</0> bölməsi vasitəsilə müraciətinizi bizə göndərə bilərsiniz. Əməkdaşlarımız ən qısa zamanda sizə geridönüş edəcək.",
    },
    willBeDiscount: {
      question: "Əməliyyatı güzəştli şəkildə icra edirəmsə, API məhsulu əldə etdikdən sonra güzəşt qüvvədə qalacaq?",
      answer: "Bəli. Tariflərlə bağlı heç bir dəyişiklik nəzərdə tutulmayıb.",
    },
    whoIsBePartnerFor: {
      question: '"Partnyor olmaq üçün müraciət" bölməsi kim üçün nəzərdə tutulub?',
      answer:
        "Mühasibatlıq xidmətlərini təklif edən şirkətlər üçün Kapital Bank ilə əməkdaşlığın rahat yoludur. API məhsullarının mühasibatlıq proqramları ilə inteqrasiyası sayəsində şirkətinizin müştəriləri əməliyyatlarını yalnız bir proqram üzərindən asanlıqla idarə edə biləcək.",
    },
  },
  Notification: {
    applyAccepted: "Müraciətiniz qeydə alındı",
    error: "Xəta",
    contractSigned: "Sənəd imzalandı",
    incorrectPhoneOrId:
      "Telefon nömrəsi və ya istifadəçi ID-si təyin olunmadı. Məlumatların düzgün daxil edildiyindən əmin olun.",
    loginError: "Login-lə bağlı xəta",
    certificateNotChoosen: "Sertifikat seçilməyib",
    notResponse: "Cavab yoxdur",
    thanksForRating: "Bizi qiymətləndirdiyiniz üçün təşəkkür edirik!",
    ratingError: "Göstərilən xidmətdən nə dərəcədə razı olduğunuzu seçin",
  },
  JoinFree: {
    title: "API Portala elə indi ödənişsiz qoşul",
    Steps: {
      Info: {
        title: "Məlumatlar",
        howToGetAsanSign: "ASAN İmza-nı necə almalı?",
      },
      ChooseCertificate: {
        title: "Sertifikat seçimi",
      },
      SignFile: {
        title: "Müqavilə imzalanması",
        contract: "Müqavilə",
      },
      Pin: {
        title: "ASAN İmza yoxlama kodu",
        text: "Telefonunuza göndərilmiş sorğunu təsdiq edin",
      },
      Success: {
        title: "E-mail ünvanınızı yoxlayın!",
        text: "API portal üzrə qeydiyyat məlumatları <1>{{userEmail}}</1> e-mail ünvanına göndərildi",
      },
      Error: {
        noAccess:
          "Təəssüf ki, sizin bu prosesə davametmə səlahiyyətiniz yoxdur. Xahiş olunur, şirkətinizin rəsmi təmsilçisinə müraciət edəsiniz.",
        noAccount: "Sizin Kapital Bank-da hesabınız yoxdur. Hesab açmaq üçün keçid:",
        noCbUser: "Sizin API portaldan istifadə hüququnuz yoxdur. «Dəstək» bölməsi vasitəsilə bizimlə əlaqə saxlayın.",
        noCertificates: "Xidmətdən istifadə etmək üçün sertifikatınız yoxdur.",
        noVersionThree:
          "Ümumi şərtlərdən ibarət yeni Kompleks müqaviləsini hazırlamışıq. Bankın xidmətlərindən istifadəyə davam etmək üçün, xahiş edirik, müqavilə ilə tanış olun və imzalayın.",
      },
      dearCustomer: "Hörmətli müştəri",
      Fields: {
        AsanPhone: {
          label: "ASAN İmza mobil nömrəsi",
          placeholder: "Məsələn: +994557040104",
        },
        UserId: {
          label: "İstifadəçi ID-si",
          placeholder: "Məsələn: 010586",
        },
        chooseCertificate: "Sertifikat seçin",
      },
    },
  },
  BePartner: {
    title: "Partnyor olmaq üçün müraciət",
    headerText: "Mühasibatlıq xidmətlərini təklif edən şirkətlər üçün Kapital Bank-la əməkdaşlıq imkanı",
    ecomInfo: "E-com üçün nəzərdə tutulmayıb",
    Fields: {
      companyName: {
        label: "Şirkətin adı",
      },
      taxId: {
        label: "VÖEN",
      },
      companyOwner: {
        label: "Şirkətin rəsmi təmsilçisinin adı",
      },
      phone: {
        label: "Əlaqə nömrəsi",
      },
      email: {
        label: "E-mail ünvanı",
      },
      note: {
        label: "Qeyd",
      },
    },
  },
  Soon: {
    title: "Yeniliklər haqda xəbərləri ilk siz öyrənin!",
    text: "E-mail ünvanınızı qeyd edin və xəbərlərimizə abunə olun.",
  },
  Action: {
    watchVideo: "Videonu izlə",
    joinFree: "Ödənişsiz qoşul",
    readMore: "daha çox",
    send: "Göndər",
    apply: "Müraciət et",
    close: "Bağla",
    backProducts: "Məhsullara qayıt",
    copied: "Kopiyalandı!",
    continue: "Davam et",
    notFound: "Məlumat tapılmadı",
    sign: "İmzala",
    view: "Endir",
    backHome: "Əsas səhifəyə qayıt",
    support: "Dəstək",
    openAccount: "Hesab aç",
    viewProducts: "API məhsullara bax",
    startTest: "Testə başla",
    downloadFile: "Endir",
    signContract: "Müqaviləni imzala",
  },
  Feedback: {
    giveFeedback: "Rəy bildir",
    title: "Portalı qiymətləndirin",
    text: "Xidmətin keyfiyyətinin artırılması üçün bizə fikrinizi bildirin",
    label: "Zəhmət olmasa, rəyinizi bizimlə bölüşün:",
  },
  Support: {
    title: "Dəstək",
    text: "Sorğunuza uyğun mövzunu seçin",
    Subjects: {
      Partner: {
        title: "Xüsusi API üçün müraciət",
        description: "Təqdim olunan API-lardan əlavə, digər funksiyalar üçün müraciət edin:",
        Note: {
          placeholder: "Tələb olunan API üçün ümumi təsvir",
        },
      },
      Test: {
        title: "Test mühitində API üçün müraciət",
        description: "Test mühiti üçün istifadəçi adı və şifrə almaq üçün müraciət edin:",
        result: {
          description: "Testə başlamaq üçün məlumatlar hazırdır",
        },
      },
      Real: {
        title: "Real rejimdə API üçün müraciət",
      },
      Ecom: {
        title: "E-ticarət platforması üçün müraciət",
        info: "Açıq API portalı e-ticarət platformaları üçün nəzərdə tutulmayıb.",
        additionalInfo: "Dəstək almaq üçün <0>012 310 10 97 </0> nömrəsi ilə əlaqə saxlaya bilərsiniz.",
      },
      Other: {
        title: "Digər",
        description: "API məhsullarımızla bağlı sual və təkliflərinizi bizə yönləndirin!",
      },
    },
    Success: {
      title: "Təşəkkür edirik!",
      info: "Müraciətinizlə bağlı sizinlə ən qısa zamanda əlaqə saxlanılacaq.",
    },
  },
  Form: {
    Fullname: {
      placeholder: "Ad və soyad",
    },
    Phone: {
      placeholder: "Telefon nömrəsi",
    },
    Email: {
      placeholder: "E-mail ünvanı",
    },
    Taxid: {
      placeholder: "VÖEN",
    },
    Note: {
      placeholder: "Suallar, təkliflər və digər qeydləriniz",
    },
    yourEmail: "E-mail ünvanınız",
    Error: {
      wrongLength: "Nömrə tam yazılmayıb",
      wrongFormat: "Yanlış format",
      taxIdFormat: "VÖEN 10 rəqəmdən ibarət olmalıdır",
    },
  },
  SignMethod: {
    title: "İmzalama metodu",
    CbSign: {
      title: "Birbank Biznes ilə imzalama",
      description:
        "Əməliyyatı öz proqram təminatınızda icra etdikdən sonra, onu imzalamaq (ASAN İmza) və ya təsdiqləmək (birdəfəlik şifrə və ya Google Auth) üçün Birbank Biznes sisteminə daxil olaraq, əməliyyatı təsdiqləyə bilərsiniz.",
    },
    SelfSign: {
      title: "Daxili sisteminizdə imzalama",
      description:
        "Apardığınız əməliyyatı daxili mühasibatlıq sisteminizdə ASAN İmza vasitəsilə imzalayaraq, ADOC formatlı faylı öz proqram təminatınızdan birbaşa banka göndərə bilərsiniz.",
    },
  },
  Footer: {
    copyright: "Copyright © {{currentYear}} Bütün hüquqlar qorunur",
  },
};
