import { Components } from "@mui/material";

export const tableTheme: Components = {
  MuiTable: {
    styleOverrides: {
      root: {},
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {},
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {},
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: "16px 24px",
        "&:hover": {},
      },
      head: {
        backgroundColor: "#EDEDED",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "24px",
        color: "#565656",
      },
      body: {
        background: "#F9F9F9",
        fontSize: "14px",
        lineHeight: "26px",
        color: "#333538",
      },
    },
  },
};
