import { createApi } from "@reduxjs/toolkit/query/react";

import { ICommonResponse } from "ts/interfaces";
import { baseQuery } from "utils/baseQuery";

import { IFeedbackParams, ISubjectParams, ISubscribeParams, IUserRq } from "./types";

export const supportApi = createApi({
  reducerPath: "supportApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    supportCreate: builder.mutation<ICommonResponse<null>, IUserRq>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "support/create",
      }),
    }),
    supportSubjectLogger: builder.mutation<null, ISubjectParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "support-subject-logger",
      }),
    }),
    feedback: builder.mutation<ICommonResponse<null>, IFeedbackParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "feedback",
      }),
    }),
    subscribe: builder.mutation<ICommonResponse<null>, ISubscribeParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "subscribe-provided-soon",
      }),
    }),
  }),
});

export const { useSupportCreateMutation, useSupportSubjectLoggerMutation, useFeedbackMutation, useSubscribeMutation } =
  supportApi;
