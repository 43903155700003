import { Components } from "@mui/material";

export const gridTheme: Components = {
  MuiGrid: {
    defaultProps: {
      spacing: 5,
    },
    styleOverrides: {
      item: {
        position: "relative",
      },
    },
  },
};
