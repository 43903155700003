import { Components } from "@mui/material";

import { colorPalette } from "./colorPalette";

export const tabTheme: Components = {
  MuiTabs: {
    styleOverrides: {
      root: {
        button: {
          border: "1px solid #CACCD0",
          color: "#25282B",
          textTransform: "none",

          "&:first-child": {
            borderRight: "none",
            borderRadius: "4px 0px 0px 4px",
          },

          "&:last-child": {
            borderLeft: "none",
            borderRadius: "0px 4px 4px 0px",
          },

          "&.Mui-selected": {
            color: "#fff",
            backgroundColor: colorPalette.text.main,
          },
        },
      },

      indicator: {
        display: "none",
      },
    },
  },
};
