export default {
  Menu: {
    home: "Home",
    products: "API products",
    usage: "How to use",
    faq: "FAQ",
  },
  Home: {
    IntroSlider: {
      Slide1: {
        title: "Both bank and accounting - in one space!",
        text: "Proceed banking transactions within your accounting system",
      },
      Slide2: {
        title: "Comfortable, fast and safe process!",
        text: "Use banking services in your software",
      },
      Slide3: {
        title: "Start a new era in banking with us",
        text: "Now you can offer your customers new business solutions",
      },
    },
    Usage: {
      title: "It is easy to join",
      text: "You just need to register in the API portal to use products",
      step1: {
        title: "Discover new possibilities",
        description: "We have a large selection of API products",
      },
      step2: {
        title: "Try our products",
        description: "Test the features you need",
      },
      step3: {
        title: "Use the service for free",
        description: "Register and sign a contract",
      },
      step4: {
        title: "Choose and use",
        description: "Our APIs are ready",
      },
    },
    Faq: {
      title: "Frequently Asked Questions",
      whatIsApi: {
        question: "What is an API?",
        answer:
          "API (Application Programming Interface) is a technical capability used to prepare software or facilitate communication between different unrelated systems.",
      },
      purposeOfApi: {
        question: "What is the purpose of API portal?",
        answer:
          "The purpose of this portal is to minimize the routine workload of our business clients. Using the API, you can easily and safely manage banking transactions directly through your own accounting software.",
      },
    },
    Advantages: {
      title: "Why to choose Kapital Bank?",
      text: "We provide your business with a ready-made platform for easy and fast account management using just one program.",
      free: {
        title: "Free of charge",
        description: "You get any API product completely free of charge, without maintenance fee",
      },
      modern: {
        title: "Modern",
        description: "Our API portal was created using the best world experience in this field",
      },
      safe: {
        title: "Safe",
        description: "All operations carried out in the system are secured and protected",
      },
      comfortable: {
        title: "Comfortable",
        description: "Integration between Kapital Bank and your business is simple and fast process",
      },
    },
  },
  Products: {
    title: "Discover our API products!",
    text: "The solutions we propose are simplify and speed up routine work for both you and your customers.",
    PublishStatus: {
      ACTIVE: "Get it",
      SOON: "Soon",
    },
    Auth: {
      title: "Authorization",
      description:
        "With help of our API, you can get a token corresponding to your username and start using our products.",
      Login: {
        title: "Login",
        description: "Review the parameters that you need to fill in for authorization in the portal.",
      },
      Refresh: {
        title: "Refresh",
        description: "Review the parameters that you need to complete for the refresh process.",
      },
    },
    Accounts: {
      title: "Account information",
      description: "Using this API, you can access information about your accounts in Kapital Bank.",
      Info: {
        title: "Current account information",
        description: "Using this API, you can get information about your current accounts in Kapital Bank.",
      },
      Statement: {
        title: "Current account statement",
        description: "Using this API, you can get a statement from your current accounts in Kapital bank.",
      },
    },
    Cards: {
      title: "Card information",
      description: "Using this API, you can get information about your cards in Kapital Bank.",
      Info: {
        title: "Business card",
        description: "Using this API, you can information about your business card in Kapital bank.",
      },
      Statement: {
        title: "Business card statement",
        description: "Using this API, you can get a statement from your business card in Kapital bank.",
      },
    },
    InternalTransfers: {
      title: "Transfers in national currency",
      description: "This API allows you to make money transfers in national currency through Kapital bank.",
      Inland: {
        title: "Money transfer within the country",
        description:
          "You can use this API to make transfers in national currency to Kapital Bank accounts or accounts in other banks in the country.",
      },
      Bulk: {
        title: "Bulk money transfers",
        description:
          "You can use this API to make bulk money transfers in national currency within the country (Number of accounts for bulk transfer - max. 50).",
      },
      BankCodes: {
        title: "List of bank codes",
        description:
          "With this API, you can get a list of bank codes used to determine the beneficiary's bank for national currency transfers.",
      },
      State: {
        title: "Transfer status",
        description: "Using this API, you can find out the status of money transfers.",
      },
      BudgetLevelCodes: {
        title: "List of budget level codes",
        description: "With this API, you can get a list of budget level codes to be used when transferring a budget.",
      },
      BudgetCodes: {
        title: "List of budget classification codes",
        description:
          "With this API, you can get a list of budget classification codes to be used when transferring a budget.",
      },
      Cancelation: {
        title: "Transfer cancelation",
        description: "Using this API, you can cancel the transfer operation.",
      },
    },
    Transfers: {
      title: "Card refill",
      description: "Using this API, you can replenish salary cards.",
      Salary: {
        title: "Salary cards replenishment",
        description: "You can use this API to top up payroll cards owned by your organization.",
      },
      State: {
        title: "Replenishment status",
        description: "Using this API, you can see the status of card replenishment operations",
      },
    },
    Comission: {
      title: "Commission fee",
      description: "You can calculate any commission through this API.",
    },
    ExternalTransfers: {
      title: "Foreign currency transfers",
      description: "This API allows you to make money transfers in foreign currency through Kapital bank.",
      External: {
        title: "International money transfer",
        description: "You can use this API to make transfers in foreign currency from Kapital Bank accounts.",
      },
      SwiftCodeList: {
        title: "SWIFT codes list",
        description: "With this API, you can get a list of SWIFT codes to be used when doing transfers.",
      },
    },
    CurrencyExchange: {
      title: "Cashless currency exchange",
      description: "Using this API, you can manage cashless currency exchange operations.",
    },
    CardOrders: {
      title: "Ordering cards",
      description: "Using this API, you can order salary cards from your own software.",
      Salary: {
        title: "Salary cards orders",
        description: "Use this API to order salary cards for your employees",
      },
      BranchList: {
        title: "List of branches",
        description: "Via this API you can get the codes of our branches",
      },
      State: {
        title: "Status of order",
        description: "Using this API, you can find out the status of orders.",
      },
      GetInfo: {
        title: "Card details",
        description: "Using provided API, you can get information about card detail.",
      },
    },
    PosInfo: {
      title: "POS-system information",
      description: "You can use these APIs to get information and statements of your POS-systems.",
      Info: {
        title: "POS-system data",
        description: "You can use this API to get information about your POS-systems.",
      },
      Statement: {
        title: "POS-system statement",
        description: "You can use this API to get statements of your POS-systems.",
      },
    },
    ECommerce: {
      title: "E-commerce info",
      description: "You can get E-commerce transaction statements through the provided APIs.",
    },
    Fields: {
      id: "ID",
      key: "Key",
      name: "Name",
      code: "Code",
      type: "Type",
      value: "Value",
      number: "Number",
      desc: "Description",
      required: "Required",
      parameter: "Parameter",
      exampleFile: "Example file",
      fileSignWithAsan: "File for signing with ASAN İmza",
    },
    Vat: {
      title: "18% VAT payment",
      description:
        "You can use this API to pay 18% VAT in transfers between accounts in national currency to Kapital Bank or other banks of the country.",
    },
    A2c: {
      title: "Transfer from account to business card",
      description: "You can use this API to replenish your company's business cards.",
    },
  },
  ProductDetails: {
    inputParameters: "Input parameters",
    outputParameters: "Output parameters",
    requestHeader: "Request header",
    requestBody: "Request body",
    operationTypes: "Operation types",
    transferPurpose: "Transfer purpose",
    destinationList: "Purpose list",
    queryParams: "Query params",
    response: "Response",
    responseCodes: "Response codes",
    endpoint: "Endpoint address",
    description: {
      //#region Authorization
      username: "Username",
      password: "Password",
      userInfo: "Name (user info)",
      surname: "Surname",
      signatureLevel: "Signature level",
      clientInfo: "Name (client info)",
      email: "Email",
      contactNumber: "Contact number",
      companyName: "Company name",
      uniqueBankNumber: "Customer's unique bank number",
      specialApproach: "For special approaches",
      customerId: "Customer ID",
      jwtrefreshtoken: "jwtrefreshtoken",
      jwtoken: "jwtoken",
      signatureType: "Signature type",
      //#endregion

      //#region Account information
      name: "Name",
      branchCode: "Branch code",
      accountNumber: "Account number",
      ibanAccountNumber: "IBAN - account number",
      currency: "Currency",
      accountType: "Account type",
      accountStatus: "Account status",
      remainingBalance: "Remaining balance after operation",
      currentAmount: "Current amount",
      blockedAmount: "Blocked amount",
      beginningDate: "Beginning date",
      endingDate: "Ending date",
      clientName: "Client name",
      accountCurrency: "Account currency",
      ibanOfAccount: "IBAN of account",
      beginningBalanceForeign: "Balance at the beginning (foreign currency)",
      endBalanceForeign: "Balance at the end (foreign currency)",
      totalReturnDebit: "Total return (debit)",
      totalReturnCredit: "Total return (credit)",
      beginningPeriod: "For the period (beginning)",
      endPeriod: "For the period (end)",
      taxId: "VÖEN",
      branchName: "Branch name",
      beginningBalanceAzn: "Balance at the beginning (AZN)",
      endBalanceAzn: "Balance at the end (AZN)",
      totalReturnDebitAzn: "Total debit return (AZN)",
      totalReturnCreditAzn: "Total credit return (AZN)",
      documentNumber: "Document Number",
      branch: "Branch",
      direction: "Direction (debit, credit)",
      valueOfAmountAzn: "The value of the amount in AZN",
      amountForeign: "Amount (foreign currency)",
      balanceForeign: "Balance (foreign currency)",
      balanceAzn: "Balance (value in AZN)",
      history: "History",
      deletionDate: "Deletion date",
      purpose: "Purpose",
      operationDate: "Date of operation",
      otherAccountInfo: "Other account number, name and TIN",
      intrabankProductCode: "Intrabank product code",
      // #endregion Account information

      //#region Card information
      serialNumber: "Serial number",
      paymentCardType: "Type of payment card",
      cardNumber: "Card number",
      expirationDate: "Expiration date",
      currentBalance: "Balance",
      operationId: "Operation ID",
      operationCode: "Operation code",
      operationDescription: "Operation description",
      transactionAmount: "Transaction amount",
      transactionCurrencyCode: "Transaction currency code",
      transactionCurrency: "Transaction currency",
      transactionExecutionDate: "Transaction execution date",
      transactionRegistrationDate: "Transaction registration date",
      equivalentAmount: "Amount (equivalent amount)",
      equivalentCurrencyCode: "Currency code (equivalent)",
      recipientAccount: "Recipient account",
      senderAccount: "Sender account",
      salePointCode: "Point of sale code",
      confirmationCode: "Confirmation code",
      encryptedPaymentCardNumber: "Encrypted payment card number",
      //#endregion Card information

      //#region Transfers in national currency
      operationNumber: "Operation number",
      beneficiaryBankCode: "Beneficiary's bank code",
      recipientAccountNumber: "Recipient account number",
      beneficiaryAccountTaxId: "VÖEN of beneficiary's account",
      recipientName: "Recipient's name",
      transferAmount: "Transfer amount",
      purpose1: "Purpose 1",
      purpose2: "Purpose 2",
      state: "Status",
      bankName: "Bank name",
      bankCode: "Bank code",
      bankTransactionId: "Bank transaction ID",
      stateDescription: "Description",
      operationCreateDate: "Operation creation date",
      budgetLevelCode: "Budget level code",
      desc: "Description",
      budgetClassificationCode: "Budget classification code",
      vatOperationName: "Enter the number of the domestic transfer transaction.",
      vatAmount: "Enter the amount to be transferred",
      vatPurpose: "Enter the purpose of the transfer",
      vatOperationId: "Transaction ID",
      vatOperationNameKey: "Transaction number",
      a2cOperationId: "Operation ID",
      a2cFromAccount: "Account number from which funds will be withdrawn",
      a2cToAccount: "Account number to which funds will be transferred",
      a2cAmount: "Amount",
      a2cPurpose: "Purpose",
      a2cFiles: "A document explaining the reason for the transfer",
      a2cBudget: "Budget",
      a2cNonBudget: "Non-budget",
      a2cRepresentation: "Representation",
      a2cBusinessTrip: "Business trip",
      a2cStatus: "Status",
      //#endregion

      //#region Transfers to cards
      senderAccountNumber: "Sender's account number",
      noteOnPurpose: "Note on the 'Other' option in the purpose",
      amount: "Amount",
      packageNumber: "Package number",
      bankId: "Bank ID",
      companyCodeCard: "Company code",
      customNoteMessage: "If the purpose is selected as 'other', then the note field is mandatory to fill",

      //#endregion

      //#region Currency exchange
      senderIban: "IBAN of the sender account",
      receiverIban: "IBAN of the beneficiary account",
      exchRate: "Exchange rate",
      operationState: "Operation status",
      //#endregion

      //#region Purpose list
      salary: "Salary",
      businessTrip: "Business trip",
      vacation: "Vacation",
      scholarship: "Scholarship",
      financialAid: "Financial aid",
      sickLeave: "Sick leave",
      compensation: "Compensation",
      bonus: "Compensation",
      childSupport: "Child support",
      rent: "Rent",
      lastBill: "Last bill",
      other: "Other",
      //#endregion

      //#region External transfer
      recipientAddress: "Recipient's address",
      swiftOrBankCode: "SWIFT or bank code",
      recipientBankName: "Bank name",
      swiftCode: "SWIFT code",
      //#endregion

      //#region Operation types
      inlandTransfer: "Transfers within the country",
      inlandTransferAbove: "Above 40,000 AZN",
      inlandTransferUnder: "Up to 40,000 AZN",
      transfersBtwbranchesAzn: "Transfers between branches - national currency (Kapital Bank)",
      transfersBtwbranchesForeign: "Transfers between branches - foreign currency (Kapital Bank)",
      budgetTransfers: "Budget transfers",
      foreignTransfers: "Foreign transfers",
      buyingCurrency: "Buying currency",
      sellingCurrency: "Sale of currency",
      cardReplenishment: "Card replenishment",
      transferWithinBranch: "Transfers within the branch (Kapital Bank)",
      cardOrder: "Card order",
      cardOrderOneYear: "1 year",
      cardOrderTwoYear: "2 years",
      urgentTransfers: "Urgent transfers (within the country)",
      salaryDesc:
        "Salary, Business trip, Vacation, Scholarship, Financial aid, Sick leave, Compensation, Bonus, Last bill",
      otherDesc: "Child support, Rent, Other",
      //#endregion

      //#region Comission
      comissionAmount: "Commission amount",
      //#endregion

      //#region Card Order
      companyCode: "Organization code",
      note: "Note",
      expirationPeriod: "Card expiration date",
      comissionAccountIban: "What account is the commission deducted from",
      receiverCardBranchCode: "Code of the branch where you can pick up the card",
      pinCode: "FIN code",
      dateOfBirth: "Date of birth",
      postalCode: "Postal code",
      phoneNumber: "Phone number",
      address: "Address",
      orderState: "Status of order",
      totalAmountOfOperations: "Total number of successful and unsuccessful operations",
      fullname: "Name, surname, patronymic of the cardholder",
      batchDate: "Card order date",
      lastFourDigits: "Last 4 digits of the card",
      cmsPackNo: "CMS package number",
      residenceAddress: "Address of residence",
      //#endregion

      //#region POS
      terminalId: "Terminal ID",
      terminalAddress: "Terminal address",
      authDate: "Authorization date",
      totalAmount: "Total",
      taksitCount: "Number of installments",
      //#endregion

      //#region Response codes
      successful: "Successful",
      methodNotAllowed: "The request model was not selected correctly",

      amountAboveZero: "Amount must be above 0",
      operationIdRequired: "Operation ID is required",
      amountRequired: "Amount is required",
      currencyRequired: "Currency is required",
      unauthorized: "Unauthorized",
      forbidden: "Forbidden",
      tokenInvalid: "Token is invalid",
      tokenExpired: "Token expired",
      errorOccured: "An error has occurred",
      wrongParameter: "Wrong parameter",
      notFound: "Not found",
      //#endregion

      //#region E-commerce
      installment: "Installment",
      operation: "Operation type",
      pan: "16-digit card number",
      sessionId: "Session number",
      terminal: "Terminal",
      paymentSource: "The source of the transaction",
      rowId: "Transaction serial number",
      rrn: "Transaction reference number",
      draw: "Page number",
      recordsFiltered: "The number of data filtered",
      recordsTotal: "Total number of data",
      totalPages: "Total page count",
      merchantId: "Merchant ID",
      orderId: "Order ID",
      page: "Default should be 0",
      sort: "Sort",
      //#endregion

      //#region Validation messages
      validationMessages: {
        currencyExchange: {
          operationId: "max. 20 symbols",
          senderIban: "28 symbols",
          receiverIban: "28 symbols",
          amount: "may contain numbers and dots",
          purpose: "max. 130 characters",
        },
        inlandTransfer: {
          senderAccount: "Max/min 28 characters (combination of English letters from A to Z and numbers from 0 to 9)",
          operationName: "max. 20 characters. Required field",
          beneficiaryBankCode: "max/min 6 characters",
          recipientAccountNumber:
            "max/min 28 characters (combination of English letters from A to Z and numbers from 0 to 9)",
          recipientTaxId: "max/min 10 characters",
          recipientName: "max. 34 characters",
          transferAmount: "only numbers and a dot or comma separating numbers",
          purpose1: "max. 64 characters, English letters only",
          purpose2: "max. 64 characters, English letters only",
        },
        salary: {
          operationId: "max. 20 characters",
          destinationList: "The list of purposes is listed below on this page",
          noteOnPurpose: "max. 50 characters",
          recipientAccountNumber: "max. 20 characters",
          recipientName: "max. 150 characters",
          amount: "only numbers and a dot or comma separating numbers",
        },
        externalTransfers: {
          fromAccount: "28 characters - combination of English letters from A to Z and numbers from 0 to 9",
          toAccount: "28 characters - combination of English letters from A to Z and numbers from 0 to 9",
          amount: "may contain numbers and dots",
        },
        cardOrder: {
          operationName: "max. 20 characters. Required",
          companyCode: "min/max 12 characters",
          expirationPeriod: "can be specified as 1, 2 or 3",
          branchCode: "3-digit number (the code can be obtained from the branch codes service)",
          pinCode: "7 characters, English font only",
          dateOfBirth: "format is dd-mm-yyyy",
          postalCode: "4 characters",
          phoneNumber: "format is 50xxxxxxx, min/max 9 characters",
          emailFormat: "Email address should be entered in correct email format.",
        },
        a2c: {
          operationId:
            "max. 20 characters. The number of the payment order must be unique (serial queue during the transfer day). It is automatically generated. You can change it",
          fromAccount: "max/min. 20 characters",
          amount: "numbers only and period or comma separating numbers",
          purpose: "1, 2, 3, 4 numbers only. You can get the list of purposes below",
        },
        vat: {
          amount: "if NULL, then 18% of the main transfer is automatically calculated and transferred",
          purpose:
            "if NULL or empty, then the 'EDV payment' sentence is added to the transaction number of the main transfer",
        },
        maxLengthMessage: "max. {{length}} characters",
        mixOfChars: "28 characters - combination of English letters from A to Z and numbers from 0 to 9",
      },
      //#endregion

      yes: "Yes",
      no: "No",
      canBeEmpty: "Can be left blank if SWIFT is entered",
      realEndpoint: "Real mode",
      testEndpoint: "Test mode",
    },
  },
  Usage: {
    title: "How to use",
    text: "You can integrate banking services directly into your accounting software through the API portal.",
    howToUse: "Terms of use",
    usageIsGuide: "You can integrate banking services directly into your accounting software through the API portal.",
    Info: {
      title: "API products information",
      products:
        'You can get acquainted with the API products provided by Kapital bank in the <0>"API products"</0> page. The first page contains a list of existing products. <br/> • Click on the name of any of the products to open a page with detailed information. <br/> • The menu on the left contains a list of available APIs for each product.',
      productInner:
        "Selecting a specific API product will display the product description, technical information about requests <i>(input-output, request-response samples)</i>, error codes, and a link to the <i>endpoint</i>. We recommend you to review this information with the technical staff of your company.",
    },
    Test: {
      title: "Test API products",
      chooseTestSubject:
        'If you want to try the API in test mode, you need to click on "Test it" button on the main page.',
      apply: "Fill in the requested information in the pop-up window and submit the request.",
      downloadTestInfo: "Download files for testing",
    },
    Apply: {
      title: "Apply for an API",
      clickJoinFree: 'Click on <0>"Join for free"</0> button.',
      approvePin1:
        "• Enter the mobile number and ID of your ASAN İmza and confirm the request on your mobile phone by entering the PIN1 code.",
      continueInfoIsValid:
        "• Based on the information from ASAN İmza, you will be able to continue the process if you are <0>a client of Kapital Bank, a legal representative of the company and Birbank Biznes user</0>.",
      chooseSignMethod:
        "On the next page, you need to choose the signing method of transactions.<br/>1. Sign via Birbank Biznes:<br/> After completing the transaction in your internal software, you can sign (ASAN İmza) or confirm the transaction (one-time password or Google Auth) by logging into .<br/>2. Sign via your internal system:<br/>By signing the transaction in your internal accounting system using ASAN İmza, you can send the final ADOC file to the bank directly from your system.",
      reviewContract:
        'After completing the previous stage, an integration contract will be formed based on your data. You can read the contract by clicking the "Download" button.',
      approvePin2:
        '• To sign the contract, press the "Sign" button and confirm the request on your mobile phone by entering the PIN2 code.',
      notificationAppear:
        "• The last page will notify you that the username created for you has been sent to your e-mail address.",
    },
    Start: {
      title: "All set",
      callEndpoints:
        'After going through the steps above, you can start sending requests to the endpoints located on the <0>"API products"</0> page.<br/>Please note that during financial transactions (for example, ordering a card, replenishing the balance, etc.), you must send us files signed through ASAN İmza, or confirm the transactions carried out in your internal system in the Birbank Biznes system.<br/>For other informational transactions (for example, an account statement, card details, etc.), this step is not required.<br/>How it proceed from a technical point of view: after entering the login and password, there is a <i>token</i> generated, which you can use for our services. Detailed technical information can be obtained using the API product called <1>"Authorization"</1>.',
    },
    Support: {
      title: "We are here to provide our support!",
      apply:
        'You can find answers to frequently asked questions in the FAQ section. If you have any other questions, you can easily contact us through the <0>"Support"</0> form located in the lower right corner of the site.',
      contactWithYou: "We will contact you immediately.",
    },
    BePartnerSupport: {
      title: "How to apply for a partnership",
      clickToBePartner: 'Choose "Request to become a partner" in the "Support" form.',
      apply: 'Fill in the required information in a new opened window and click the "Send" button.',
      weWillContactYou: "Our staff will contact you shortly.",
    },
  },
  Faq: {
    title: "Frequently Asked Questions",
    text: "Here you can find answers to the most frequently asked questions about the API portal.",
    whatIsApi: {
      question: "What is an API?",
      answer:
        "API (Application Programming Interface) is a technical capability used to prepare software or facilitate communication between different unrelated systems.<br/>Briefly, an API is a common language that allows different software products to integrate and work together.<br/>For example, with our API products, transactions from your accounting software will be reflected directly in the banking system, and you will immediately receive information about the status of transactions.",
    },
    whatIsApiPortal: {
      question: "What is an API portal?",
      answer:
        "A portal is a tool that reflects information about all the implemented APIs and the integration sequence.",
    },
    purposeOfApi: {
      question: "What is the purpose of API portal?",
      answer:
        "The purpose of this portal is to minimize the routine workload of our business clients. Using the API, you can easily and safely manage banking transactions directly through your own accounting software.",
    },
    howToTest: {
      question: "Can I test an API?",
      answer:
        'If you want to try out the API in test mode, then you need to go to the "Support" section and select "Request API for testing".',
    },
    isPaid: {
      question: "Is there a fee to use the API portal?",
      answer: "No. Our users can use the portal and API products completely free of charge.",
    },
    whoCanUse: {
      question: "Who can use the API portal?",
      answer:
        "The API portal is created for our business customers. For ease of interaction with products, we recommend our customers to involve programmers or technical staff in working with the portal.",
    },
    howToGet: {
      question: "How to get the API?",
      answer:
        'The main condition for obtaining the API is to be a client of Kapital Bank.<br/>By clicking the <1>"Join for free"</1> button on the main page, you will be taken to the registration page. Then, the official representative of your company signs the agreement, by which your business receives the rightto use the API.<br/>For more details, please visit the <0>"How to use"</0> page.',
    },
    whoCanSign: {
      question: "Who can sign the contract?",
      answer:
        "The integration contract can only be signed via ASAN İmza by the official representative of the company.",
    },
    isMailValidForCb: {
      question: "How to access the Birbank Biznes system?",
      answer:
        "To simplify the integration process, we send your login and password to your e-mail address immediately after registering on the Birbank Biznes website. With the login and password you will be able to send requests directly from your software, as well as conduct transactions in the Birbank Biznes system.",
    },
    howToResetPassword: {
      question: "What if I forgot my password?",
      answer: "Lost password can be easily restored on the Birbank Biznes website: <0>cb.kapitalbank.az</0>.",
    },
    hasAccesWithoutRegistration: {
      question: "Can I use the API without registering to Birbank Biznes?",
      answer:
        "If you have not yet joined Birbank Biznes, then you can register on the <0>onb-cb.kapitalbank.az</0> page. You can then start using API products from our portal.",
    },
    willOperationsAvailableOnCb: {
      question: "Does my transactions displayed in Birbank Biznes?",
      answer:
        "Yes. Transactions that you make through your company's accounting software will also be reflected in the Birbank Biznes system. However, transactions that you make only in the Birbank Biznes system will not be reflected in your software.",
    },
    howToTestOnSelf: {
      question: "How can I test the API in my software?",
      answer:
        'You can test our products through the endpoints listed in the "API products" page, using your own software or certain developer tools (for example, Postman, Swagger, etc.).',
    },
    howIsSecurity: {
      question: "How is security ensured?",
      answer:
        "The security of the portal is based on the AUTH, all requests enter our system through an encrypted token.",
    },
    howToContact: {
      question: "How can I contact you?",
      answer:
        'If you have any questions or conserns, you can send us your request through the <0>"Support"</0> form located in the lower right corner of this site. We will contact you as soon as possible.',
    },
    willBeDiscount: {
      question: "If I make a transaction with a discount, will the discount still apply after using the API?",
      answer: "Yes. There are no changes in tariffs.",
    },
    whoIsBePartnerFor: {
      question: 'Who can use the "Request to become a partner" section?',
      answer:
        "This is a convenient way of cooperation with Kapital Bank for companies offering accounting services. By integrating API products with accounting software that you offer, your customers can easily manage their transactions with just one program.",
    },
  },
  Notification: {
    applyAccepted: "Request accepted",
    error: "Error",
    contractSigned: "Contract has been signed",
    incorrectPhoneOrId: "Mobile phone number or user ID not found. Please, check again",
    loginError: "Login error",
    certificateNotChoosen: "Certificate not selected",
    notResponse: "No response",
    thanksForRating: "Thank you for your rating!",
    ratingError: "How would you rate us?",
  },
  JoinFree: {
    title: "Join to the portal for free right now!",
    Steps: {
      Info: {
        title: "Information",
        howToGetAsanSign: "How to get ASAN İmza?",
      },
      ChooseCertificate: {
        title: "Choose a certificate",
      },
      SignFile: {
        title: "Signing a contract",
        contract: "Contract",
      },
      Pin: {
        title: "ASAN Imza verification code",
        text: "Confirm the request sent to your number",
      },
      Success: {
        title: "Check your e-mail",
        text: "Your registration details on API portal was sent to your e-mail address <1>{{userEmail}}</1>",
      },
      Error: {
        noAccess: 'You do not have rights to use the API Portal. Contact us via the "Support".',
        noAccount: "You do not have an account in Kapital Bank. To create an account follow the link:",
        noCbUser: 'You are not a Birbank Biznes user. Contact us via the "Support".',
        noCertificates: "You do not have a certificate to use the service.",
        noVersionThree:
          "We have prepared a new Complex agreement with general terms and conditions. To continue the use of bank's services, please read and sign this agreement.",
      },
      dearCustomer: "Dear customer",
      Fields: {
        AsanPhone: {
          label: "ASAN İmza mobile number",
          placeholder: "e.g., +994557040104",
        },
        UserId: {
          label: "User ID",
          placeholder: "e.g., 010586",
        },
        chooseCertificate: "Choose certificate",
      },
    },
  },
  BePartner: {
    title: "Request to become a partner",
    headerText: "Kapital Bank offers cooperation for companies offering accounting services",
    ecomInfo: "Not provided for e-commerce platforms",
    Fields: {
      companyName: {
        label: "Company name",
      },
      taxId: {
        label: "VÖEN",
      },
      companyOwner: {
        label: "Official representative of the company",
      },
      phone: {
        label: "Phone number",
      },
      email: {
        label: "E-mail",
      },
      note: {
        label: "Your message",
      },
    },
  },
  Soon: {
    title: "Be the first to get the news!",
    text: "Enter your email to subscribe to our updates.",
  },
  Action: {
    watchVideo: "Watch",
    joinFree: "Join for free",
    readMore: "show more",
    send: "Send",
    apply: "Send request",
    close: "Close",
    backProducts: "Back to products page",
    copied: "Copied!",
    continue: "Continue",
    notFound: "Not found",
    sign: "Sign",
    view: "Read",
    backHome: "Back to Home",
    support: "Support",
    openAccount: "Create an account",
    viewProducts: "View API products",
    startTest: "Start testing",
    downloadFile: "Download",
    signContract: "Sign the agreement",
  },
  Feedback: {
    giveFeedback: "Rate us",
    title: "Rate us",
    text: "Give us your feedback, so we can create better experience for you",
    label: "Please, share your thoughts below:",
  },
  Support: {
    title: "Support",
    text: "Choose the topic of your message",
    Subjects: {
      Partner: {
        title: "Request for a custom API",
        description: "If you need an API with features not listed on this website:",
        Note: {
          placeholder: "Brief description of required functions",
        },
      },
      Test: {
        title: "Request API for testing",
        description: "Request a username and password for the test environment:",
        result: {
          description: "Test details are ready",
        },
      },
      Real: {
        title: "Request API for production",
      },
      Ecom: {
        title: "Request for e-commerce platform",
        info: "The Open API portal is not for use on e-commerce platforms.",
        additionalInfo: "You can contact support by calling <0>012 310 10 97</0>",
      },
      Other: {
        title: "Other",
        description: "We are here to answer your questions about Open API!",
      },
    },
    Success: {
      title: "Thank you for your request!",
      info: "We will contact you shortly",
    },
  },
  Form: {
    Fullname: {
      placeholder: "Name and Last name",
    },
    Phone: {
      placeholder: "Mobile phone number",
    },
    Email: {
      placeholder: "E-mail address",
    },
    Taxid: {
      placeholder: "VÖEN",
    },
    Note: {
      placeholder: "Your questions, comments, suggestions",
    },
    yourEmail: "E-mail address",
    Error: {
      wrongLength: "Number is not correct",
      wrongFormat: "Wrong format",
      taxIdFormat: "VÖEN must be contain 10 digits",
    },
  },
  SignMethod: {
    title: "Signature method",
    CbSign: {
      title: "Sign via Birbank Biznes",
      description:
        "After completing a transaction in your software, you can sign (ASAN İmza) or confirm the transaction (one-time password or Google Auth) by logging into Birbank Biznes.",
    },
    SelfSign: {
      title: "Sign via your internal system",
      description:
        "After signing the transaction in your internal accounting system using ASAN İmza, you can send the final ADOC file to the bank directly from your system.",
    },
  },
  Footer: {
    copyright: "Copyright © {{currentYear}} All rights reserved",
  },
};
