import { i18n } from "i18next";

import { ROUTES } from "consts/routes";

export const menuItems = (i18n: i18n) => [
  {
    title: i18n.t("Menu.home"),
    path: ROUTES.HOME.PATH,
    exact: true,
  },
  {
    title: i18n.t("Menu.products"),
    path: ROUTES.PRODUCTS.PATH,
  },
  {
    title: i18n.t("Menu.usage"),
    path: ROUTES.HOW_TO_USE.PATH,
  },
  {
    title: i18n.t("Menu.faq"),
    path: ROUTES.FAQ.PATH,
  },
];
