import { ELanguage } from "ts/enums/enums";

export const APP_DEFAULT_LANG = ELanguage.AZ;
export const TOKEN_NAME = "accessToken";
export const PHONE_NUMBER = "(012) 310 09 99";
export const HOME_PAGE_TITLE = "API Portal | Kapital Bank - Bank xidmətlərinin mühasibat sisteminizə inteqrasiyası";
export const GET_API_PAGE_TITLE = "API portal ilə rahat və sürətli inteqarasiya | Kapital Bank";
export const PRODUCTS_PAGE_TITLE = "API Portal - API məhsulları - Kapital Bank";
export const HOW_TO_USE_PAGE_TITLE = "API Portal | API test rejimi - Kapital Bank";
export const FORM_DATA_JSON = "--form-data(text):json";
export const FORM_DATA_SIGN_FILE = "--form-data: signFile";
