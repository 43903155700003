import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

//TODO: Hooks yapilacak.
const ScrollToTop: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [history]);

  return <></>;
};

export default ScrollToTop;
