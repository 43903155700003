import { createApi } from "@reduxjs/toolkit/query/react";

import { ICommonResponse } from "ts/interfaces";
import { baseQuery } from "utils/baseQuery";

import {
  IAccessCreateResponse,
  IAddOnFileSignParams,
  IAsanAdvanceLoginParams,
  IAsanLoginParams,
  ICheckWithAsanParams,
  ICheckWithAsanResponse,
  ICreateContractParams,
  ICreateContractResponse,
  IDownloadContractParams,
  IJoinFreeLoggerParams,
  ILoginWitAsanResponse,
  ILoginWithAsanAdvanceResponse,
  ISignFileResponse,
  ISignParams,
} from "./types";

export const downloadFileHelper = async (response: Response) => {
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "Contract.pdf";
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const joinFreeApi = createApi({
  reducerPath: "joinFreeApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    checkWithAsan: builder.mutation<ICommonResponse<ICheckWithAsanResponse>, ICheckWithAsanParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "checkWithAsan",
      }),
    }),
    loginWithAsan: builder.mutation<ICommonResponse<ILoginWitAsanResponse>, IAsanLoginParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "loginWithAsan",
      }),
    }),
    loginWithAsanAdvance: builder.mutation<ICommonResponse<ILoginWithAsanAdvanceResponse>, IAsanAdvanceLoginParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "loginWithAsanAdvance",
      }),
    }),
    createContract: builder.mutation<ICommonResponse<ICreateContractResponse>, ICreateContractParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "contract/create",
      }),
    }),
    download: builder.mutation<Blob, IDownloadContractParams>({
      query: (params) => ({
        timeout: 0,
        params: params,
        responseType: "blob",
        url: "file/download",
        responseHandler: downloadFileHelper,
      }),
    }),
    signFile: builder.mutation<ISignFileResponse, ISignParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: `sign-file/${body.id}`,
      }),
    }),
    addOnFileSign: builder.mutation<ICommonResponse<null>, IAddOnFileSignParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "addonfile/sign",
      }),
    }),
    accessCreate: builder.mutation<ICommonResponse<IAccessCreateResponse>, void>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "access/create",
      }),
    }),
    joinFreeLogger: builder.mutation<null, IJoinFreeLoggerParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "join-free-logger-without-token",
      }),
    }),
    joinFreeLoggerWithToken: builder.mutation<null, IJoinFreeLoggerParams>({
      query: (body) => ({
        method: "POST",
        body: body,
        url: "join-free-logger-with-token",
      }),
    }),
  }),
});

export const {
  useCheckWithAsanMutation,
  useLoginWithAsanMutation,
  useLoginWithAsanAdvanceMutation,
  useCreateContractMutation,
  useDownloadMutation,
  useSignFileMutation,
  useAddOnFileSignMutation,
  useAccessCreateMutation,
  useJoinFreeLoggerMutation,
  useJoinFreeLoggerWithTokenMutation,
} = joinFreeApi;
