import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { TOKEN_NAME } from "consts";
import i18n from "services/i18n";

const currentLanguage = () => i18n.language;

const baseUrl = {
  local: "https://dev.cb.kapitalbank.az/api/portal/",
  development: "https://dev.cb.kapitalbank.az/api/portal/",
  qa: "https://qa-cb.kapitalbank.az/api/portal/",
  preprod: "https://pre-cb.kapitalbank.az/api/portal/",
  production: "https://cb.kapitalbank.az/api/portal/",
}[process.env.REACT_APP_BUILD_ENV as string];

const fetchQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    const authToken = localStorage.getItem(TOKEN_NAME);
    const language = currentLanguage();

    if (authToken) {
      headers.set("authorization", `Bearer ${authToken}`);
    }
    headers.set("Accept-Language", language);
    return headers;
  },
});

export const baseQuery: BaseQueryFn<FetchArgs | string, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  try {
    const result = await fetchQuery(args, api, extraOptions);
    if (result.error) {
      return { error: result.error };
    }
    return { data: result.data };
  } catch (error) {
    console.error(error);
    throw error;
  }
};
