import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";

import contextSlice from "./context";
import { joinFreeApi } from "./joinFree/joinFreeApi";
import { apiStatusMiddleware } from "./middleware/apiStatus";
import { supportApi } from "./support/supportApi";

const rootReducer = combineReducers({
  [supportApi.reducerPath]: supportApi.reducer,
  [joinFreeApi.reducerPath]: joinFreeApi.reducer,
  context: contextSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(apiStatusMiddleware, supportApi.middleware, joinFreeApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
