import { Components } from "@mui/material";

export const accordion: Components = {
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        marginBottom: "16px",
        "&::before": {
          display: "none",
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        backgroundColor: "#FCFCFC",
        borderRadius: "4px",
        padding: "20px 24px",

        "&.Mui-expanded": {
          backgroundColor: "rgba(0, 159, 247,0.12)",

          "&.product-summary": {
            backgroundColor: "rgba(249, 249, 250, 0.5)",

            "& p": {
              color: "#333538",
            },
          },
        },
      },
      content: {
        margin: 0,
        p: {
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "28px",
        },

        "&.Mui-expanded": {
          margin: 0,

          p: {
            color: "#009FF7",
            fontWeight: 600,
          },
        },
      },
      expandIconWrapper: {
        padding: "7px",
        backgroundColor: "rgba(82, 87, 92, 0.1)",
        borderRadius: "4px",
        img: {
          width: "12px",
        },
      },
    },
  },
  MuiCollapse: {
    styleOverrides: {
      wrapper: {},
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        color: "#6F6F6F",
        backgroundColor: "#FCFCFC",
        padding: "24px",
        width: "auto",

        "& a": {
          color: "#009FF7",
        },
      },
    },
  },
};
