import React from "react";

export interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  /** Image path relative to images folder */
  imgName: string;
}

/** Fix prop type later */
const getImgSrc = (name: string) => require(`assets/images/${name}`);

const Img: React.FC<IProps> = ({ imgName, ...rest }) => {
  return <img src={getImgSrc(imgName)} {...rest} alt={imgName} />;
};

export default Img;
